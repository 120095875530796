import { IsDefined, IsString, IsUUID, IsEmail, IsOptional, IsBoolean, IsNumber, IsNotEmpty } from "class-validator";
import { v4 as uuid } from "uuid";

export class ApiPartnerModel {
    @IsUUID("4")
    @IsDefined()
    public _id: string = uuid();

    @IsString()
    @IsDefined()
    public name: string;

    @IsEmail()
    @IsDefined()
    public email: string;

    @IsString()
    @IsDefined()
    @IsNotEmpty()
    public apiKey: string;

    @IsString()
    @IsOptional()
    public phoneNumber?: string;

    @IsUUID("4")
    @IsDefined()
    public countryId: string;

    @IsBoolean()
    @IsDefined()
    public emailsEnabled: boolean = false;

    @IsNumber()
    @IsDefined()
    public discountPercentage: number;

    @IsUUID("4")
    @IsDefined()
    public ownerId: string;
}
