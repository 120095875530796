import { TransformToDate } from "@daytrip/legacy-transformers";
import { Expose } from "class-transformer";
import { IsDate, IsDefined, IsInt, IsNumber, IsOptional, IsString, IsUUID } from "class-validator";
import { v4 as uuid } from "uuid";

import { PartnerType } from "../domain/PartnerType";
import { CDBaseEntity, CDColumn, CDCreateDateColumn, CDEntity, CDObjectIdColumn } from "../orm";

@CDEntity("partners")
export class Partner extends CDBaseEntity {
    public static referral(): Partner {
        const partner = new Partner();
        partner.type = PartnerType.Referral;
        partner.expirationDays = 1;
        return partner;
    }

    @CDObjectIdColumn()
    @IsUUID("4")
    @Expose()
    public _id: string = uuid();

    @CDColumn()
    @IsNumber()
    @IsDefined()
    @Expose()
    public expirationDays: number = 5;

    @CDColumn()
    @IsString()
    @IsDefined()
    @Expose()
    public name: string = "";

    @CDColumn()
    @IsString()
    @Expose()
    public description?: string;

    @CDColumn()
    @IsString()
    @Expose()
    public alias?: string;

    @CDColumn()
    @IsInt()
    @IsDefined()
    @Expose()
    public type: PartnerType;

    @CDColumn()
    @IsString()
    @Expose()
    public key?: string;

    @CDColumn()
    @IsString()
    @IsDefined()
    @Expose()
    public locationId?: string;

    @CDCreateDateColumn()
    @IsDate()
    @IsDefined()
    @Expose()
    @TransformToDate
    public createdAt: Date = new Date();

    @CDCreateDateColumn()
    @IsDate()
    @IsOptional()
    @Expose()
    @TransformToDate
    public deletedAt?: Date;

    public isDeleted(): boolean {
        return !!this.deletedAt;
    }
}
