import { Partner } from "@legacy/models/Partner";
import { RetrievePartnersOptions } from "@legacy/options/RetrievePartnersOptions";
import autobind from "autobind-decorator";
import { action, observable } from "mobx";

import { PageStore } from "../../stores/PageStore";

import { PartnersRouter } from "./PartnersRouter";

@autobind
export class PartnersStore extends PageStore<PartnersRouter, null> {
    @observable
    public partners?: Array<Partner>;

    @action
    public async onFetchData() {
        await this.fetchContent();
    }

    @action
    public async fetchContent(): Promise<void> {
        const options = {
            skip: this.pageRouter.skip,
            limit: this.pageRouter.limit,
        } as RetrievePartnersOptions;
        this.partners = await this.rpcClient.partner.retrievePartnersDataForPartnersPage(options);
    }

    public isDataFetched(): this is PartnersStore & PartnersStoreDataFetched {
        return !!this.partners;
    }
}

export interface PartnersStoreDataFetched {
    partners: Array<Partner>;
}
