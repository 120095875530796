import { ApolloError } from "@apollo/client";
import {
    PoolAvailabilityConfigOverviewsForTimespanProps,
    PoolAvailabilityConfigOverview,
} from "@legacy/services/PoolAvailabilityConfigService.type";
import { action, observable } from "mobx";

import { PageStore } from "../../stores/PageStore";

import { PoolAvailabilityConfigsPageRouter } from "./PoolAvailabilityConfigsPageRouter";

export class PoolAvailabilityConfigsPageStore extends PageStore<PoolAvailabilityConfigsPageRouter> {
    @observable
    availabilityConfigsTotalCount: number | undefined = undefined;

    @observable
    availabilityConfigs: PoolAvailabilityConfigOverview[] | undefined = [];

    @observable
    public loading = false;

    @observable
    public error: ApolloError | undefined;

    private extractQueryVariables({
        offset,
        limit,
        sortBy,
        sortDirection,
        departureAtFrom,
        departureAtTo,
    }: PoolAvailabilityConfigOverviewsForTimespanProps) {
        const queryVariables: PoolAvailabilityConfigOverviewsForTimespanProps = {};
        if (offset) {
            queryVariables.offset = offset - 1;
        }
        if (limit) {
            queryVariables.limit = limit;
        }
        if (sortBy) {
            queryVariables.sortBy = sortBy;
        }
        if (sortDirection) {
            queryVariables.sortDirection = sortDirection;
        }
        if (departureAtFrom) {
            queryVariables.departureAtFrom = departureAtFrom;
        }
        if (departureAtTo) {
            queryVariables.departureAtTo = departureAtTo;
        }
        return queryVariables;
    }

    @action
    public async fetchAvailabilityConfigs(queryParams: PoolAvailabilityConfigOverviewsForTimespanProps) {
        const extractedQueryVariables = this.extractQueryVariables(queryParams);
        this.loading = true;
        let response;
        try {
            response = await this.rpcClient.poolAvailabilityConfig.getPoolAvailabilityConfigOverviewsForTimespan(
                extractedQueryVariables,
            );
        } catch (error) {
            this.error = error;
        }
        this.availabilityConfigs = response.data;
        this.availabilityConfigsTotalCount = response.totalCount;
        this.loading = false;
    }
}
