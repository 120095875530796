import type { TargetingLocation } from "../domain/TargetingLocation";
import { TargetingLocationType } from "../domain/TargetingLocationType";

export function transformCountryNameToTargetingLocation(
    countryNames: Array<string>,
    type?: string,
): Array<TargetingLocation> {
    return countryNames.map(
        (countryName) =>
            ({
                locationName: countryName,
                type: type || TargetingLocationType.Country,
            } as TargetingLocation),
    );
}
