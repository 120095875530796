import { Exclude, Expose, Type } from "class-transformer";
import {
    ArrayNotEmpty,
    IsArray,
    IsBoolean,
    IsDate,
    IsDefined,
    IsNumber,
    IsString,
    IsUUID,
    Length,
    ValidateNested,
    IsEnum,
    IsOptional,
} from "class-validator";

import type { ComboType } from "../../../api/src/domain/drivers/combo/models/combo-snapshot.model";
import { Assignation } from "../models/Assignation";
import { OrderContentLocationForAssignation } from "../models/OrderContentLocationForAssignation";

import { Currency } from "./Currency";
import type { LuggageType } from "./LuggageType";
import { SimpleDriverWithVehicles } from "./SimpleDriverWithVehicles";
import { SimplePassenger } from "./SimplePassenger";
import { SimpleUser } from "./SimpleUser";
import { VehicleType } from "./VehicleType";

export type ComboAssigntionToolProtectionStatus =
    | "Postponed"
    | "Unreviewed"
    | "Multiple Combo Options"
    | "Combo Predicted"
    | ComboType;

@Exclude()
export class SimpleOrderForAssignation {
    @IsUUID("4")
    @IsDefined()
    @Expose()
    public orderId: string;

    @IsDefined()
    @Expose()
    public customer: SimpleUser;

    @IsNumber()
    @IsDefined()
    @Expose()
    public price: number;

    @IsNumber()
    @IsDefined()
    @Expose()
    public fee: number;

    @IsEnum(Currency)
    @IsDefined()
    @Expose()
    public pricingCurrency: Currency = Currency.Euro;

    @IsDate()
    @IsDefined()
    @Expose()
    @Type(() => Date)
    public departureAt: Date;

    @IsNumber()
    @IsDefined()
    @Expose()
    public vehicleType: VehicleType;

    @ArrayNotEmpty()
    @IsArray()
    @IsDefined()
    @Expose()
    @Type(() => SimplePassenger)
    public simplePassengers: Array<SimplePassenger>;

    @IsArray()
    @IsDefined()
    @Expose()
    @Type(() => Number)
    public luggage: Array<LuggageType>;

    @Length(1, 64)
    @IsString()
    @IsDefined()
    @Expose()
    public originLocationName: string;

    @IsUUID("4")
    @IsDefined()
    @Expose()
    public originLocationId: string;

    @IsString()
    @Expose()
    public originCountryName: string;

    @IsUUID("4")
    @IsDefined()
    @Expose()
    public originCountryId: string;

    @IsUUID("4")
    @IsDefined()
    @Expose()
    public destinationCountryId: string;

    @Length(1, 64)
    @IsString()
    @IsDefined()
    @Expose()
    public destinationLocationName: string;

    @IsUUID("4")
    @IsDefined()
    @Expose()
    public destinationLocationId: string;

    @IsDefined()
    @Expose()
    public assignation?: Assignation;

    @IsDefined()
    @Expose()
    public assignedDriver?: SimpleDriverWithVehicles;

    @IsBoolean()
    @IsDefined()
    @Expose()
    public hasAssignation: boolean;

    @IsBoolean()
    @IsDefined()
    @Expose()
    public hasDeclinedAssignation: boolean;

    @IsBoolean()
    @IsDefined()
    @Expose()
    public hasAssignationOffer: boolean;

    @IsBoolean()
    @IsOptional()
    @Expose()
    public protectedComboState?: ComboAssigntionToolProtectionStatus;

    @IsString()
    @IsDefined()
    @Expose()
    public customerNote: string;

    @IsString()
    @IsDefined()
    @Expose()
    public managementNote: string;

    @IsArray()
    @IsDefined()
    @ValidateNested()
    @Expose()
    @Type(() => OrderContentLocationForAssignation)
    public contentLocations: OrderContentLocationForAssignation[] = [];

    @IsArray()
    @IsDefined()
    @Expose()
    public orderVehicles: VehicleType[];

    @IsBoolean()
    @IsOptional()
    @Expose()
    public isAirportTransfer?: boolean;

    @IsBoolean()
    @IsOptional()
    @Expose()
    public isVIP?: boolean;
}
