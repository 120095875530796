import { startsWithCountryCode } from "@daytrip/utils";
import type { Address } from "@legacy/domain/Address";
import type { BillingInformation } from "@legacy/domain/BillingInformation";
import { CountryBankType } from "@legacy/domain/CountryBankType";
import { Currency } from "@legacy/domain/Currency";
import type { SimpleCountry } from "@legacy/domain/SimpleCountry";
import { UBO } from "@legacy/domain/UBO";
import { UBODeclaration } from "@legacy/domain/UBODeclaration";
import type { VatValidation } from "@legacy/domain/VatValidation";
import type { MangopayInformation } from "@legacy/models/MangopayInformation";
import autobind from "autobind-decorator";
import { validate } from "class-validator";
import { action, computed, observable } from "mobx";

import { IUBODeclarationStatus } from "../../../legacy/declarations/mangopay2-nodejs-sdk";

import type { DocumentTypeOperator } from "./DocumentTypeOperator";
import { ModelOperator } from "./ModelOperator";
import type { ModelOperatorOptions } from "./ModelOperatorOptions";
import { UBOOperator } from "./UBOOperator";

interface BillingInformationOperatorOptions
    extends ModelOperatorOptions<BillingInformation, null, BillingInformationOperatorData> {
    isNew?: boolean;
}

interface BillingInformationOperatorData {
    simpleCountries: Array<SimpleCountry>;
    mangopayInformation: MangopayInformation | undefined;
    userId: string;
    mangopayUserId?: string;
}

interface BillingInformationOperatorDataFetched extends BillingInformationOperatorData {
    simpleCountries: Array<SimpleCountry>;
}

@autobind
export class BillingInformationOperator extends ModelOperator<
    BillingInformation,
    BillingInformationOperatorOptions,
    null,
    BillingInformationOperatorData,
    BillingInformationOperatorDataFetched
> {
    public constructor(options: BillingInformationOperatorOptions) {
        super(options);

        this.isNew = Boolean(options.isNew);
        this.bankAccountId = this.data.mangopayInformation?.getBankAccount(this.currency)?.bankAccountId;

        if (!this.isNew && this.m.uboDeclaration) {
            this.uboOperators = (this.m.uboDeclaration.ubos || []).map(
                (ubo) =>
                    new UBOOperator({
                        modelConstructor: UBO,
                        model: ubo,
                        data: {
                            simpleCountries: this.data.simpleCountries,
                        },
                        modules: {},
                        onSave: async () => {},
                    }),
            );
        }

        this.checkIfVatIdValidationIsSupported();
        this.setVatValidationValues();
    }

    @observable
    public uboOperators: Array<UBOOperator> = [];

    @computed
    public get currency() {
        const country = this.data.simpleCountries.find((c) => c._id === this.model.bankAccountCountryId);
        return country?.pricingCurrency ?? Currency.Euro;
    }

    @action
    public addUBO() {
        const newUBO = new UBO();
        const newUBOOperator = new UBOOperator({
            modelConstructor: UBO,
            model: newUBO,
            data: {
                simpleCountries: this.data.simpleCountries,
            },
            modules: {},
            onSave: async () => {},
        });

        this.uboOperators.push(newUBOOperator);

        if (!this.isEdited()) {
            console.log("SET EDITING");
            this.edit();
        }
    }

    @action
    public async removeUBO(i: number) {
        const uboId = this.uboOperators[i].m.id;

        if (!uboId) {
            this.uboOperators = this.uboOperators.filter((_, uboI) => uboI !== i);
        } else {
            const mangopayUserId = this.m.uboDeclaration!.userId;
            const uboDeclarationId = this.m.uboDeclaration!.id;

            await this.rpcClient.mangopay.deactivateUBO(mangopayUserId, uboDeclarationId, uboId as string);

            this.uboOperators = this.uboOperators.filter((_, uboI) => uboI !== i);

            this.edit(() => {});
            this.save();
        }
    }

    @observable
    public isUpdatingUBOStatus: boolean = false;

    @action
    public async updateUBOStatus() {
        if (!this.m.uboDeclaration) {
            return;
        }

        const uboDeclarationId = this.m.uboDeclaration.id;

        this.isUpdatingUBOStatus = true;
        const updatedUBODeclaration = await this.rpcClient.mangopay.updateUBOStatus(this.data.userId, uboDeclarationId);
        this.isUpdatingUBOStatus = false;

        if (!updatedUBODeclaration) {
            alert("Unable to update UBO status. Please, try again later");
            return;
        }

        this.m.uboDeclaration.status = updatedUBODeclaration.status;
        this.m.uboDeclaration.reason = updatedUBODeclaration.reason;
        this.m.uboDeclaration.message = updatedUBODeclaration.message;
    }

    @observable
    public isSubmittingForValidation: boolean = false;

    @action
    public async submitForValidation() {
        if (!this.m.uboDeclaration) {
            return;
        }

        if (
            window.confirm(
                "Are you sure you want to send it? You will not be able to add more shareholders until the UBO is approved or declined by Mangopay.",
            )
        ) {
            this.isSubmittingForValidation = true;

            try {
                await this.rpcClient.mangopay.submitUBODeclarationForValidation(
                    this.m.uboDeclaration.userId,
                    this.m.uboDeclaration.id,
                );
            } catch (e: any) {
                if (e.Message) {
                    alert(e.Message);
                }
            }

            this.isSubmittingForValidation = false;

            await this.updateUBOStatus();
        }
    }

    @computed
    public get uboCanBeEdited(): boolean {
        return this.m.uboDeclaration
            ? ![
                  IUBODeclarationStatus.VALIDATION_ASKED,
                  IUBODeclarationStatus.REFUSED,
                  IUBODeclarationStatus.VALIDATED,
              ].includes(this.m.uboDeclaration.status)
            : false;
    }

    @action
    public async createNewUBODeclaration() {
        if (!this.data.mangopayUserId) {
            return;
        }

        this.isCreatingUBODeclaration = true;

        const uboDeclaration = await this.rpcClient.mangopay.createMangopayUBOData(
            this.data.mangopayUserId,
            new UBODeclaration(),
        );
        this.uboOperators = [];

        if (uboDeclaration) {
            this.edit((o) => {
                o.uboDeclaration = uboDeclaration;
            });
            this.save();
        }

        this.isCreatingUBODeclaration = false;
    }

    @observable
    public isCreatingUBODeclaration: boolean = false;

    @observable
    public isNew: boolean;

    @observable
    public birthAtDay: number;

    @observable
    public birthAtMonth: number;

    @observable
    public birthAtYear: number;

    @observable
    public documentTypeOperators: Array<DocumentTypeOperator> = [];

    @action
    public setDocumentTypeOperators(documentTypeOperators: Array<DocumentTypeOperator>): void {
        this.documentTypeOperators = documentTypeOperators;
    }

    @action
    public updateBirthAtDay(value: number) {
        this.updateBirthdayAt({ day: value });
    }

    @action
    public updateBirthAtMonth(value: number) {
        this.updateBirthdayAt({ month: value });
    }

    @action
    public updateBirthAtYear(value: number) {
        this.updateBirthdayAt({ year: value });
    }

    @computed
    public get nationality(): string {
        const nationality = this.data.simpleCountries.find((sc) => sc._id === this.m.nationalityCountryId);
        return nationality !== undefined ? nationality.englishName : "";
    }

    @computed
    public get bankAccountCountry() {
        const bankAccountCountry = this.data.simpleCountries.find((sc) => sc._id === this.m.bankAccountCountryId);
        return bankAccountCountry;
    }

    @computed
    public get countryOfResidence(): string {
        const countryOfResidence = this.data.simpleCountries.find((sc) => sc._id === this.m.residenceCountryId);
        return countryOfResidence !== undefined ? countryOfResidence.englishName : "";
    }

    @computed
    public get addressCountry(): string {
        const addressCountry = this.data.simpleCountries.find((sc) => sc._id === this.m.address.countryId);
        return addressCountry !== undefined ? addressCountry.englishName : "";
    }

    @action
    protected updateBirthdayAt(options: Partial<{ day: number; month: number; year: number }>) {
        const birthdate = this.m.birthdayAt || new Date(0);

        const { day, month, year } = {
            day: options.day !== undefined ? options.day : birthdate?.getUTCDate(),
            month: options.month !== undefined ? options.month : birthdate?.getUTCMonth(),
            year: options.year !== undefined ? options.year : birthdate?.getUTCFullYear(),
        };

        if (day !== undefined && month !== undefined && year !== undefined) {
            const date: Date = new Date(year, month, day, 9);
            if (date !== undefined) {
                this.m.birthdayAt = new Date(date);
            }
        }
    }

    @action
    public onBankAccountCountryChange(countryId: string) {
        this.edit((o) => {
            o.bankAccountCountryId = countryId;
        });

        switch (this.bankAccountCountry?.bankType) {
            case CountryBankType.IBAN:
                this.edit((o) => {
                    o.bankType = CountryBankType.IBAN;
                    o.bic = undefined;
                    o.aba = undefined;
                    o.accountNumber = undefined;
                });
                break;
            case CountryBankType.BIC:
                this.edit((o) => {
                    o.bankType = CountryBankType.BIC;
                    o.aba = undefined;
                    o.iban = undefined;
                });
                break;
            case CountryBankType.US:
                this.edit((o) => {
                    o.bankType = CountryBankType.US;
                    o.bic = undefined;
                    o.iban = undefined;
                });
                break;
            default:
                this.edit((o) => {
                    o.iban = undefined;
                    o.bic = undefined;
                    o.aba = undefined;
                    o.accountNumber = undefined;
                });
                break;
        }
    }

    @computed
    public get getCountryIdsToShowBic(): string {
        const addressCountry = this.data.simpleCountries.find((sc) => sc._id === this.m.address.countryId);
        return addressCountry !== undefined ? addressCountry.englishName : "";
    }

    @action
    public blurIban() {
        if (this.m.iban) {
            this.m.iban = this.cleanBankAccountInput(this.m.iban);
        }
    }

    @action
    public blurBIC() {
        if (this.m.bic) {
            this.m.bic = this.cleanBankAccountInput(this.m.bic);
        }
    }

    @action
    public blurABA() {
        if (this.m.aba) {
            this.m.aba = this.cleanBankAccountInput(this.m.aba);
        }
    }

    @action
    public blurAccountNumber() {
        if (this.m.accountNumber) {
            this.m.accountNumber = this.m.accountNumber.replace(/ /g, "").replace(/\./g, "").replace(/-/g, "");
        }
    }

    @action
    public async setActive() {
        this.model.isActive = true;
        const currentDateValue = this.m.activatedAt;
        this.m.activatedAt = new Date();
        await this.validate();
        if (this.validations.length) {
            this.model.isActive = false;
            this.m.activatedAt = currentDateValue;
            alert(
                "Warning: Please edit the form and add the required data if you want to set this Billing Information as active",
            );
            return;
        }
        await this.onSave(this.model);
        await this.onUpdateStoreVatValidationResponse();
    }

    @action
    public async setUsedAsInvoiceInfo() {
        this.model.isInvoiceInformation = true;
        const validationErrors = await validate(this.model);

        const vatError = validationErrors.find((e) => e.property === "vatNumber");

        if (vatError) {
            this.model.isInvoiceInformation = false;
            alert(
                "VAT number is incorrect. Please, correct it before setting billing information as invoice information.",
            );
            this.edit((o) => {
                o.vatNumber = "";
            });

            return;
        }

        if (validationErrors.length) {
            this.model.isInvoiceInformation = false;
            alert(
                "Warning: Please edit the form and add the required data if you want to set this Billing Information as invoice information.",
            );
            return;
        }

        await this.onSave(this.model);
    }

    @action
    public async saveAndSetActive() {
        this.m.isActive = true;
        const currentDateValue = this.m.activatedAt;
        this.m.activatedAt = new Date();
        try {
            await this.save();
        } catch (e: any) {
            this.m.isActive = false;
            this.m.activatedAt = currentDateValue;
        }

        // unable to catch error - if still in edit mode, then wasn't saved
        if (this.isEdited()) {
            this.m.isActive = false;
            this.m.activatedAt = currentDateValue;
        }
        await this.onUpdateStoreVatValidationResponse();
    }

    @action
    public async cancelEditAndResetVatValidation() {
        this.resetVatValidationValues();
        this.cancelEdit();
        await this.onUpdateStoreVatValidationResponse();
    }

    @action
    public async saveEditAndValidateVatNumber() {
        await this.onUpdateStoreVatValidationResponse();
        this.save();
    }

    @observable
    public headquartersTheSameAsLegalAddress?: boolean = false;

    @action
    public async triggerHeadquartersAddressCopyFromLegal(sameAsLegal: boolean) {
        if (sameAsLegal) {
            this.m.headquartersAddress = observable({ ...this.m.address });
            await this.validate(this.validateOptions);
        } else {
            this.m.headquartersAddress = observable({
                addressLine1: "",
                addressLine2: "",
                city: "",
                countryId: "",
                region: "",
                postalCode: "",
            } as Address);
        }

        this.headquartersTheSameAsLegalAddress = sameAsLegal;
    }

    @observable
    public bankAccountId: string | undefined;

    @action
    public updateBankAccountId(value: string): void {
        this.bankAccountId = value;
    }

    private cleanBankAccountInput(input: string) {
        return input.replace(/ /g, "").replace(/\./g, "").replace(/-/g, "");
    }

    @observable
    public isVatValidationSupported: boolean = false;

    @observable
    public vatValidationError: boolean = false;

    @observable
    public vatValidationResponse: VatValidation | undefined;

    @computed
    public get countryIdForVatValidation() {
        return this.m.headquartersAddress?.countryId;
    }

    @action
    public async checkIfVatIdValidationIsSupported() {
        const countryId = this.countryIdForVatValidation;
        // billing type natural person, does not have HQ address, but he is not allowed to enter VAT
        if (!countryId) {
            return false;
        }

        this.isVatValidationSupported = await this.rpcClient.vat.isVatIdValidationSupported(countryId);
    }

    @action
    public async validateVatId() {
        const countryId = this.countryIdForVatValidation;
        const vatId = this.m.vatNumber;
        if (!vatId || !countryId) {
            return;
        }
        try {
            const response = await this.rpcClient.vat.validateVatId({ countryId, vatId });
            this.m.vatValidation = {
                isValid: response.isValid,
                errorMessage: response.message,
                validationDate: new Date(),
                businessAddress: response.businessAddress,
                businessName: response.businessName,
            };
            this.vatValidationResponse = this.m.vatValidation;
            this.vatValidationError = false;
        } catch (e: any) {
            this.vatValidationError = true;
            alert(
                `Error while validating VAT number: ${
                    e?.message || JSON.stringify(e)
                }.\nIf you are saving billing information, the process will continue. Please check the VAT and country of residence, and retry validation later.`,
            );
        }
    }

    @computed
    public get isVatStartingWithCountryCode() {
        return this.m.vatNumber && this.startsWithHQCountryCode(this.m.vatNumber);
    }

    private startsWithHQCountryCode(vatNumber: string): boolean {
        const countryId = this.countryIdForVatValidation;
        const country = this.data.simpleCountries.find((c) => c._id === countryId);
        if (!country) {
            return false;
        }

        return startsWithCountryCode(vatNumber, country.isoCode);
    }

    @action
    public async onUpdateStoreVatValidationResponse() {
        if (this.isVatValidationSupported) {
            await this.validateVatId();
        }
    }

    @action
    public updateVatNumber(vatNumber: string) {
        this.edit((o) => {
            o.vatNumber = vatNumber;
        });
        this.resetVatValidationValues();
    }

    @action
    public updateResidenceCountryId({ value }: { value: string }) {
        this.edit((o) => {
            o.residenceCountryId = value as string;
        });
        this.resetVatValidationValues();
    }

    @action
    public setVatValidationValues() {
        this.vatValidationError = false;
        this.vatValidationResponse = this.m.vatValidation;
    }

    @action
    private resetVatValidationValues() {
        this.vatValidationError = false;
        this.m.vatValidation = undefined;
        this.vatValidationResponse = undefined;
    }
}
