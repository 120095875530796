import { omit } from "lodash/fp";
import sortBy from "lodash/sortBy";

import type { DynamicFormConfigFieldInput } from "../../types/generated/graphql-types";

import type { DynamicFormConfigFieldTypeExtended } from "./CooperationAgreementPage.types";

export const preparePayloadData = (tableData: DynamicFormConfigFieldTypeExtended[]): DynamicFormConfigFieldInput[] => {
    return sortBy(tableData, ["order"])
        .map(omit(["isEdit", "__typename", "type"]))
        .map(
            (fields, idx) =>
                ({
                    ...fields,
                    order: idx + 1,
                }) as DynamicFormConfigFieldInput,
        );
};
