/* eslint-disable */
// @ts-nocheck
import * as Types from "./graphql-types";

import { DocumentNode } from "graphql";
import gql from "graphql-tag";
export type AssignationOfferClaimForTableFragment = {
    _id: string;
    createdAt: any;
    acceptedAt?: any | null;
    confirmedAt?: any | null;
    declinedAt?: any | null;
    cancelledAt?: any | null;
    userId: string;
    driverUserId: string;
    status: number;
    biddingPrice?: number | null;
    driver: { firstName: string; lastName: string };
    user: { isDriversCompany: boolean; driversCompany?: { name: string } | null };
};

export type ConfirmAssignationOfferByClaimMutationVariables = Types.Exact<{
    assignationOfferId: Types.Scalars["String"]["input"];
    claimId: Types.Scalars["String"]["input"];
}>;

export type ConfirmAssignationOfferByClaimMutationOperationResult = {
    confirmAssignationOfferByClaim: Types.ConfirmAssignationOffersResult;
};

export type GetAllCountriesForSelectQueryVariables = Types.Exact<{ [key: string]: never }>;

export type GetAllCountriesForSelectQueryOperationResult = {
    countriesByEnglishName: Array<{ _id: string; isoCode: string; englishName: string }>;
};

export type GetDynamicFormConfigQueryVariables = Types.Exact<{
    input: Types.DynamicFormConfigFindOneInput;
}>;

export type GetDynamicFormConfigQueryOperationResult = {
    getDynamicFormConfig: {
        _id: string;
        countryId?: string | null;
        entityType: Types.DynamicFormConfigEntityType;
        entityTypeLegalName: string;
        formType: Types.DynamicFormConfigFormType;
        fields: Array<{
            englishName: string;
            fieldName: string;
            isRequired: boolean;
            localName: string;
            order: number;
            placeholder: string;
        }>;
    };
};

export type UpsertDynamicFormConfigMutationVariables = Types.Exact<{
    input: Types.CreateDynamicFormConfigInput;
}>;

export type UpsertDynamicFormConfigMutationOperationResult = {
    upsertDynamicFormConfig: {
        _id: string;
        countryId?: string | null;
        entityType: Types.DynamicFormConfigEntityType;
        entityTypeLegalName: string;
        formType: Types.DynamicFormConfigFormType;
        fields: Array<{
            englishName: string;
            fieldName: string;
            isRequired: boolean;
            localName: string;
            order: number;
            placeholder: string;
        }>;
    };
};

export type AssignationOfferForOverviewFragment = {
    _id: string;
    type: Types.AssignationOfferType;
    departureAt: any;
    adults: number;
    children: number;
    vehicleType: Types.VehicleType;
    note?: string | null;
    originLocationName: string;
    originLocationId: string;
    destinationLocationName: string;
    destinationLocationId: string;
    currency: number;
    status: Types.AssignationOfferStatus;
    orderId: string;
    subsidy?: number | null;
    orderStatus: Types.OrderStatus;
    suggestedBiddingPrice?: number | null;
    totalPrice: { total: number; fee: number };
    claims: Array<{
        _id: string;
        createdAt: any;
        acceptedAt?: any | null;
        confirmedAt?: any | null;
        declinedAt?: any | null;
        cancelledAt?: any | null;
        userId: string;
        driverUserId: string;
        status: number;
        biddingPrice?: number | null;
        driver: { firstName: string; lastName: string };
        user: { isDriversCompany: boolean; driversCompany?: { name: string } | null };
    }>;
};

export type AssignationOffersForOverviewQueryVariables = Types.Exact<{
    orderId?: Types.InputMaybe<Types.Scalars["String"]["input"]>;
    vehicleTypes?: Types.InputMaybe<Array<Types.VehicleType> | Types.VehicleType>;
    pricingCountryIds?: Types.InputMaybe<Array<Types.Scalars["String"]["input"]> | Types.Scalars["String"]["input"]>;
    orderStatuses?: Types.InputMaybe<Array<Types.OrderStatus> | Types.OrderStatus>;
    statuses?: Types.InputMaybe<Array<Types.AssignationOfferStatus> | Types.AssignationOfferStatus>;
    limit?: Types.InputMaybe<Types.Scalars["Int"]["input"]>;
    offset?: Types.InputMaybe<Types.Scalars["Int"]["input"]>;
    sortBy?: Types.InputMaybe<Types.Scalars["String"]["input"]>;
    sortDirection?: Types.InputMaybe<Types.Scalars["Int"]["input"]>;
}>;

export type AssignationOffersForOverviewQueryOperationResult = {
    assignationOffersForOverview: {
        totalCount: number;
        data: Array<{
            _id: string;
            type: Types.AssignationOfferType;
            departureAt: any;
            adults: number;
            children: number;
            vehicleType: Types.VehicleType;
            note?: string | null;
            originLocationName: string;
            originLocationId: string;
            destinationLocationName: string;
            destinationLocationId: string;
            currency: number;
            status: Types.AssignationOfferStatus;
            orderId: string;
            subsidy?: number | null;
            orderStatus: Types.OrderStatus;
            suggestedBiddingPrice?: number | null;
            totalPrice: { total: number; fee: number };
            claims: Array<{
                _id: string;
                createdAt: any;
                acceptedAt?: any | null;
                confirmedAt?: any | null;
                declinedAt?: any | null;
                cancelledAt?: any | null;
                userId: string;
                driverUserId: string;
                status: number;
                biddingPrice?: number | null;
                driver: { firstName: string; lastName: string };
                user: { isDriversCompany: boolean; driversCompany?: { name: string } | null };
            }>;
        }>;
    };
};

export type DriverCountryRuleForEditFragment = {
    driverCountryId: string;
    originCountryId: string;
    destinationCountryId: string;
    canAcceptAssignationOffers: boolean;
    canBeAutomaticallyAssigned: boolean;
};

export type DriverCountryRuleForTableFragment = {
    driverCountryId: string;
    originCountryId: string;
    destinationCountryId: string;
    canAcceptAssignationOffers: boolean;
    canBeAutomaticallyAssigned: boolean;
    driverCountry: { isoCode: string; englishName: string };
    originCountry: { isoCode: string; englishName: string };
    destinationCountry: { isoCode: string; englishName: string };
};

export type CreateDriverCountryRuleMutationVariables = Types.Exact<{
    input: Types.DriverCountryRuleInput;
}>;

export type CreateDriverCountryRuleMutationOperationResult = { createDriverCountryRule: boolean };

export type DeleteDriverCountryRuleMutationVariables = Types.Exact<{
    driverCountryId: Types.Scalars["String"]["input"];
    originCountryId: Types.Scalars["String"]["input"];
    destinationCountryId: Types.Scalars["String"]["input"];
}>;

export type DeleteDriverCountryRuleMutationOperationResult = { deleteDriverCountryRule: boolean };

export type UpdateDriverCountryRuleMutationVariables = Types.Exact<{
    driverCountryId: Types.Scalars["String"]["input"];
    originCountryId: Types.Scalars["String"]["input"];
    destinationCountryId: Types.Scalars["String"]["input"];
    update: Types.DriverCountryRuleUpdateInput;
}>;

export type UpdateDriverCountryRuleMutationOperationResult = { updateDriverCountryRule: boolean };

export type GetDriverCountryRuleForEditQueryVariables = Types.Exact<{
    driverCountryId: Types.Scalars["String"]["input"];
    originCountryId: Types.Scalars["String"]["input"];
    destinationCountryId: Types.Scalars["String"]["input"];
}>;

export type GetDriverCountryRuleForEditQueryOperationResult = {
    driverCountryRule: {
        driverCountryId: string;
        originCountryId: string;
        destinationCountryId: string;
        canAcceptAssignationOffers: boolean;
        canBeAutomaticallyAssigned: boolean;
    };
};

export type ListDriverCountryRulesForTableQueryVariables = Types.Exact<{
    driverCountryIds?: Types.InputMaybe<Array<Types.Scalars["String"]["input"]> | Types.Scalars["String"]["input"]>;
    originCountryIds?: Types.InputMaybe<Array<Types.Scalars["String"]["input"]> | Types.Scalars["String"]["input"]>;
    destinationCountryIds?: Types.InputMaybe<
        Array<Types.Scalars["String"]["input"]> | Types.Scalars["String"]["input"]
    >;
    offset?: Types.InputMaybe<Types.Scalars["Int"]["input"]>;
    limit?: Types.InputMaybe<Types.Scalars["Int"]["input"]>;
}>;

export type ListDriverCountryRulesForTableQueryOperationResult = {
    driverCountryRules: {
        totalCount: number;
        data: Array<{
            driverCountryId: string;
            originCountryId: string;
            destinationCountryId: string;
            canAcceptAssignationOffers: boolean;
            canBeAutomaticallyAssigned: boolean;
            driverCountry: { isoCode: string; englishName: string };
            originCountry: { isoCode: string; englishName: string };
            destinationCountry: { isoCode: string; englishName: string };
        }>;
    };
};

export type AddCrossBorderTripWhitelistEntryMutationVariables = Types.Exact<{
    entry: Types.CrossBorderTripWhitelistEntryInput;
}>;

export type AddCrossBorderTripWhitelistEntryMutationOperationResult = { addCrossBorderTripWhitelistEntry: boolean };

export type CountriesByEnglishNameQueryVariables = Types.Exact<{ [key: string]: never }>;

export type CountriesByEnglishNameQueryOperationResult = { countriesByEnglishName: Array<{ isoCode: string }> };

export type CrossBorderWhitelistQueryVariables = Types.Exact<{ [key: string]: never }>;

export type CrossBorderWhitelistQueryOperationResult = {
    flexibleOffersRuntimeConfig: {
        crossBorderTripWhitelist: Array<{
            countryIsoA: string;
            countryIsoB: string;
            additionalFee?: number | null;
            additionalPrice?: number | null;
            preferredPricing?: string | null;
        }>;
    };
};

export type RemoveCrossBorderTripWhitelistEntryMutationVariables = Types.Exact<{
    entry: Types.CrossBorderTripWhitelistEntryInput;
}>;

export type RemoveCrossBorderTripWhitelistEntryMutationOperationResult = {
    removeCrossBorderTripWhitelistEntry: boolean;
};

export type UpdateCrossBorderTripWhitelistEntryMutationVariables = Types.Exact<{
    entry: Types.CrossBorderTripWhitelistEntryInput;
}>;

export type UpdateCrossBorderTripWhitelistEntryMutationOperationResult = {
    updateCrossBorderTripWhitelistEntry: boolean;
};

export type FlexibleOffersGlobalScalarConfigQueryVariables = Types.Exact<{ [key: string]: never }>;

export type FlexibleOffersGlobalScalarConfigQueryOperationResult = {
    flexibleOffersGlobalScalarConfig: Array<{ key: Types.FlexibleOffersRuntimeConfigScalarKeys; value: number }>;
};

export type UpdateFoScalarConfigParameterMutationVariables = Types.Exact<{
    key: Types.FlexibleOffersRuntimeConfigScalarKeys;
    value: Types.Scalars["Int"]["input"];
}>;

export type UpdateFoScalarConfigParameterMutationOperationResult = { configEntryPutScalarParameters: boolean };

export type CancelAssignationOfferMutationVariables = Types.Exact<{
    offerId: Types.Scalars["String"]["input"];
}>;

export type CancelAssignationOfferMutationOperationResult = { cancelAssignationOffer: boolean };

export type ConfirmAssignationOffersForOrderMutationVariables = Types.Exact<{
    orderId: Types.Scalars["String"]["input"];
}>;

export type ConfirmAssignationOffersForOrderMutationOperationResult = {
    confirmAssignationOffersForOrder: Types.ConfirmAssignationOffersResult;
};

export type CreateAssignationOffersForOrderMutationVariables = Types.Exact<{
    orderId: Types.Scalars["String"]["input"];
    subsidies: Array<Types.Scalars["Float"]["input"]> | Types.Scalars["Float"]["input"];
    note?: Types.InputMaybe<Types.Scalars["String"]["input"]>;
}>;

export type CreateAssignationOffersForOrderMutationOperationResult = { createAssignationOffersForOrder: boolean };

export type CreateAssignationOfferForVehicleMutationVariables = Types.Exact<{
    input: Types.CreateAssignationOfferForVehicleInput;
}>;

export type CreateAssignationOfferForVehicleMutationOperationResult = { createAssignationOfferForVehicle: boolean };

export type AssignationOfferForOrderFragment = {
    _id: string;
    type: Types.AssignationOfferType;
    createdAt: any;
    departureAt: any;
    status: Types.AssignationOfferStatus;
    children: number;
    vehicleType: Types.VehicleType;
    adults: number;
    note?: string | null;
    subsidy?: number | null;
    isValid: boolean;
    currency: number;
    suggestedBiddingPrice?: number | null;
    totalPrice: { total: number; fee: number };
    claims: Array<{
        _id: string;
        createdAt: any;
        acceptedAt?: any | null;
        confirmedAt?: any | null;
        declinedAt?: any | null;
        cancelledAt?: any | null;
        userId: string;
        driverUserId: string;
        status: number;
        biddingPrice?: number | null;
        driver: { firstName: string; lastName: string };
        user: { isDriversCompany: boolean; driversCompany?: { name: string } | null };
    }>;
};

export type AssignationOffersForOrderQueryVariables = Types.Exact<{
    orderId?: Types.InputMaybe<Types.Scalars["String"]["input"]>;
}>;

export type AssignationOffersForOrderQueryOperationResult = {
    assignationOffers: {
        data: Array<{
            _id: string;
            type: Types.AssignationOfferType;
            createdAt: any;
            departureAt: any;
            status: Types.AssignationOfferStatus;
            children: number;
            vehicleType: Types.VehicleType;
            adults: number;
            note?: string | null;
            subsidy?: number | null;
            isValid: boolean;
            currency: number;
            suggestedBiddingPrice?: number | null;
            totalPrice: { total: number; fee: number };
            claims: Array<{
                _id: string;
                createdAt: any;
                acceptedAt?: any | null;
                confirmedAt?: any | null;
                declinedAt?: any | null;
                cancelledAt?: any | null;
                userId: string;
                driverUserId: string;
                status: number;
                biddingPrice?: number | null;
                driver: { firstName: string; lastName: string };
                user: { isDriversCompany: boolean; driversCompany?: { name: string } | null };
            }>;
        }>;
    };
};

export type UpdateRideMutationVariables = Types.Exact<{
    ride: Types.RideInput;
}>;

export type UpdateRideMutationOperationResult = { updateRide: string };

export interface PossibleTypesResultData {
    possibleTypes: {
        [key: string]: string[];
    };
}
const result: PossibleTypesResultData = {
    possibleTypes: {
        FlexibleOffersRuntimeConfigValues: [
            "FlexibleOffersRuntimeConfigValueEnabledRegions",
            "FlexibleOffersRuntimeConfigValueNumber",
        ],
    },
};
export default result;

export const AssignationOfferClaimForTableFragmentDoc = gql`
    fragment AssignationOfferClaimForTable on AssignationOfferClaim {
        _id
        createdAt
        acceptedAt
        confirmedAt
        declinedAt
        cancelledAt
        userId
        driverUserId
        driver {
            firstName
            lastName
        }
        user {
            driversCompany {
                name
            }
            isDriversCompany
        }
        status
        biddingPrice
    }
`;
export const AssignationOfferForOverviewFragmentDoc = gql`
    fragment AssignationOfferForOverview on AssignationOfferForOverview {
        _id
        type
        departureAt
        adults
        children
        vehicleType
        note
        originLocationName
        originLocationId
        destinationLocationName
        destinationLocationId
        currency
        totalPrice {
            total
            fee
        }
        status
        claims {
            ...AssignationOfferClaimForTable
        }
        orderId
        subsidy
        orderStatus
        suggestedBiddingPrice
    }
    ${AssignationOfferClaimForTableFragmentDoc}
`;
export const DriverCountryRuleForEditFragmentDoc = gql`
    fragment DriverCountryRuleForEdit on DriverCountryRule {
        driverCountryId
        originCountryId
        destinationCountryId
        canAcceptAssignationOffers
        canBeAutomaticallyAssigned
    }
`;
export const DriverCountryRuleForTableFragmentDoc = gql`
    fragment DriverCountryRuleForTable on DriverCountryRule {
        driverCountryId
        driverCountry {
            isoCode
            englishName
        }
        originCountryId
        originCountry {
            isoCode
            englishName
        }
        destinationCountryId
        destinationCountry {
            isoCode
            englishName
        }
        canAcceptAssignationOffers
        canBeAutomaticallyAssigned
    }
`;
export const AssignationOfferForOrderFragmentDoc = gql`
    fragment AssignationOfferForOrder on AssignationOffer {
        _id
        type
        createdAt
        departureAt
        status
        children
        vehicleType
        adults
        note
        subsidy
        totalPrice {
            total
            fee
        }
        claims {
            ...AssignationOfferClaimForTable
        }
        isValid
        currency
        suggestedBiddingPrice
    }
    ${AssignationOfferClaimForTableFragmentDoc}
`;
export const ConfirmAssignationOfferByClaimDocument = gql`
    mutation ConfirmAssignationOfferByClaim($assignationOfferId: String!, $claimId: String!) {
        confirmAssignationOfferByClaim(assignationOfferId: $assignationOfferId, claimId: $claimId)
    }
`;
export const GetAllCountriesForSelectDocument = gql`
    query GetAllCountriesForSelect {
        countriesByEnglishName {
            _id
            isoCode
            englishName
        }
    }
`;
export const GetDynamicFormConfigDocument = gql`
    query GetDynamicFormConfig($input: DynamicFormConfigFindOneInput!) {
        getDynamicFormConfig(dynamicFormConfigFindOneInput: $input) {
            _id
            countryId
            entityType
            entityTypeLegalName
            fields {
                englishName
                fieldName
                isRequired
                localName
                order
                placeholder
            }
            formType
        }
    }
`;
export const UpsertDynamicFormConfigDocument = gql`
    mutation UpsertDynamicFormConfig($input: CreateDynamicFormConfigInput!) {
        upsertDynamicFormConfig(upsertDynamicFormConfigInput: $input) {
            _id
            countryId
            entityType
            entityTypeLegalName
            formType
            fields {
                englishName
                fieldName
                isRequired
                localName
                order
                placeholder
            }
        }
    }
`;
export const AssignationOffersForOverviewDocument = gql`
    query AssignationOffersForOverview(
        $orderId: String
        $vehicleTypes: [VehicleType!]
        $pricingCountryIds: [String!]
        $orderStatuses: [OrderStatus!]
        $statuses: [AssignationOfferStatus!]
        $limit: Int
        $offset: Int
        $sortBy: String
        $sortDirection: Int
    ) {
        assignationOffersForOverview(
            orderId: $orderId
            vehicleTypes: $vehicleTypes
            pricingCountryIds: $pricingCountryIds
            orderStatuses: $orderStatuses
            statuses: $statuses
            limit: $limit
            offset: $offset
            sortBy: $sortBy
            sortDirection: $sortDirection
        ) {
            data {
                ...AssignationOfferForOverview
            }
            totalCount
        }
    }
    ${AssignationOfferForOverviewFragmentDoc}
`;
export const CreateDriverCountryRuleDocument = gql`
    mutation CreateDriverCountryRule($input: DriverCountryRuleInput!) {
        createDriverCountryRule(input: $input)
    }
`;
export const DeleteDriverCountryRuleDocument = gql`
    mutation DeleteDriverCountryRule(
        $driverCountryId: String!
        $originCountryId: String!
        $destinationCountryId: String!
    ) {
        deleteDriverCountryRule(
            driverCountryId: $driverCountryId
            originCountryId: $originCountryId
            destinationCountryId: $destinationCountryId
        )
    }
`;
export const UpdateDriverCountryRuleDocument = gql`
    mutation UpdateDriverCountryRule(
        $driverCountryId: String!
        $originCountryId: String!
        $destinationCountryId: String!
        $update: DriverCountryRuleUpdateInput!
    ) {
        updateDriverCountryRule(
            driverCountryId: $driverCountryId
            originCountryId: $originCountryId
            destinationCountryId: $destinationCountryId
            update: $update
        )
    }
`;
export const GetDriverCountryRuleForEditDocument = gql`
    query GetDriverCountryRuleForEdit(
        $driverCountryId: String!
        $originCountryId: String!
        $destinationCountryId: String!
    ) {
        driverCountryRule(
            driverCountryId: $driverCountryId
            originCountryId: $originCountryId
            destinationCountryId: $destinationCountryId
        ) {
            ...DriverCountryRuleForEdit
        }
    }
    ${DriverCountryRuleForEditFragmentDoc}
`;
export const ListDriverCountryRulesForTableDocument = gql`
    query ListDriverCountryRulesForTable(
        $driverCountryIds: [String!]
        $originCountryIds: [String!]
        $destinationCountryIds: [String!]
        $offset: Int
        $limit: Int
    ) {
        driverCountryRules(
            driverCountryIds: $driverCountryIds
            originCountryIds: $originCountryIds
            destinationCountryIds: $destinationCountryIds
            offset: $offset
            limit: $limit
        ) {
            data {
                ...DriverCountryRuleForTable
            }
            totalCount
        }
    }
    ${DriverCountryRuleForTableFragmentDoc}
`;
export const AddCrossBorderTripWhitelistEntryDocument = gql`
    mutation addCrossBorderTripWhitelistEntry($entry: CrossBorderTripWhitelistEntryInput!) {
        addCrossBorderTripWhitelistEntry(crossBorderTripWhitelistEntry: $entry)
    }
`;
export const CountriesByEnglishNameDocument = gql`
    query countriesByEnglishName {
        countriesByEnglishName {
            isoCode
        }
    }
`;
export const CrossBorderWhitelistDocument = gql`
    query crossBorderWhitelist {
        flexibleOffersRuntimeConfig {
            crossBorderTripWhitelist {
                countryIsoA
                countryIsoB
                additionalFee
                additionalPrice
                preferredPricing
            }
        }
    }
`;
export const RemoveCrossBorderTripWhitelistEntryDocument = gql`
    mutation removeCrossBorderTripWhitelistEntry($entry: CrossBorderTripWhitelistEntryInput!) {
        removeCrossBorderTripWhitelistEntry(crossBorderTripWhitelistEntry: $entry)
    }
`;
export const UpdateCrossBorderTripWhitelistEntryDocument = gql`
    mutation updateCrossBorderTripWhitelistEntry($entry: CrossBorderTripWhitelistEntryInput!) {
        updateCrossBorderTripWhitelistEntry(crossBorderTripWhitelistEntry: $entry)
    }
`;
export const FlexibleOffersGlobalScalarConfigDocument = gql`
    query flexibleOffersGlobalScalarConfig {
        flexibleOffersGlobalScalarConfig {
            key
            value
        }
    }
`;
export const UpdateFoScalarConfigParameterDocument = gql`
    mutation updateFOScalarConfigParameter($key: FlexibleOffersRuntimeConfigScalarKeys!, $value: Int!) {
        configEntryPutScalarParameters(RuntimeConfigEntry: { key: $key, value: $value, ttl: 60 })
    }
`;
export const CancelAssignationOfferDocument = gql`
    mutation CancelAssignationOffer($offerId: String!) {
        cancelAssignationOffer(offerId: $offerId)
    }
`;
export const ConfirmAssignationOffersForOrderDocument = gql`
    mutation ConfirmAssignationOffersForOrder($orderId: String!) {
        confirmAssignationOffersForOrder(orderId: $orderId)
    }
`;
export const CreateAssignationOffersForOrderDocument = gql`
    mutation CreateAssignationOffersForOrder($orderId: String!, $subsidies: [Float!]!, $note: String) {
        createAssignationOffersForOrder(orderId: $orderId, note: $note, subsidies: $subsidies)
    }
`;
export const CreateAssignationOfferForVehicleDocument = gql`
    mutation CreateAssignationOfferForVehicle($input: CreateAssignationOfferForVehicleInput!) {
        createAssignationOfferForVehicle(input: $input)
    }
`;
export const AssignationOffersForOrderDocument = gql`
    query AssignationOffersForOrder($orderId: String) {
        assignationOffers(orderId: $orderId) {
            data {
                ...AssignationOfferForOrder
            }
        }
    }
    ${AssignationOfferForOrderFragmentDoc}
`;
export const UpdateRideDocument = gql`
    mutation UpdateRide($ride: RideInput!) {
        updateRide(ride: $ride)
    }
`;
export type Requester<C = {}> = <R, V>(doc: DocumentNode, vars?: V, options?: C) => Promise<R> | AsyncIterable<R>;
export function getSdk<C>(requester: Requester<C>) {
    return {
        ConfirmAssignationOfferByClaim(
            variables: Types.ConfirmAssignationOfferByClaimMutationVariables,
            options?: C,
        ): Promise<Types.ConfirmAssignationOfferByClaimMutationOperationResult> {
            return requester<
                Types.ConfirmAssignationOfferByClaimMutationOperationResult,
                Types.ConfirmAssignationOfferByClaimMutationVariables
            >(
                ConfirmAssignationOfferByClaimDocument,
                variables,
                options,
            ) as Promise<Types.ConfirmAssignationOfferByClaimMutationOperationResult>;
        },
        GetAllCountriesForSelect(
            variables?: Types.GetAllCountriesForSelectQueryVariables,
            options?: C,
        ): Promise<Types.GetAllCountriesForSelectQueryOperationResult> {
            return requester<
                Types.GetAllCountriesForSelectQueryOperationResult,
                Types.GetAllCountriesForSelectQueryVariables
            >(
                GetAllCountriesForSelectDocument,
                variables,
                options,
            ) as Promise<Types.GetAllCountriesForSelectQueryOperationResult>;
        },
        GetDynamicFormConfig(
            variables: Types.GetDynamicFormConfigQueryVariables,
            options?: C,
        ): Promise<Types.GetDynamicFormConfigQueryOperationResult> {
            return requester<Types.GetDynamicFormConfigQueryOperationResult, Types.GetDynamicFormConfigQueryVariables>(
                GetDynamicFormConfigDocument,
                variables,
                options,
            ) as Promise<Types.GetDynamicFormConfigQueryOperationResult>;
        },
        UpsertDynamicFormConfig(
            variables: Types.UpsertDynamicFormConfigMutationVariables,
            options?: C,
        ): Promise<Types.UpsertDynamicFormConfigMutationOperationResult> {
            return requester<
                Types.UpsertDynamicFormConfigMutationOperationResult,
                Types.UpsertDynamicFormConfigMutationVariables
            >(
                UpsertDynamicFormConfigDocument,
                variables,
                options,
            ) as Promise<Types.UpsertDynamicFormConfigMutationOperationResult>;
        },
        AssignationOffersForOverview(
            variables?: Types.AssignationOffersForOverviewQueryVariables,
            options?: C,
        ): Promise<Types.AssignationOffersForOverviewQueryOperationResult> {
            return requester<
                Types.AssignationOffersForOverviewQueryOperationResult,
                Types.AssignationOffersForOverviewQueryVariables
            >(
                AssignationOffersForOverviewDocument,
                variables,
                options,
            ) as Promise<Types.AssignationOffersForOverviewQueryOperationResult>;
        },
        CreateDriverCountryRule(
            variables: Types.CreateDriverCountryRuleMutationVariables,
            options?: C,
        ): Promise<Types.CreateDriverCountryRuleMutationOperationResult> {
            return requester<
                Types.CreateDriverCountryRuleMutationOperationResult,
                Types.CreateDriverCountryRuleMutationVariables
            >(
                CreateDriverCountryRuleDocument,
                variables,
                options,
            ) as Promise<Types.CreateDriverCountryRuleMutationOperationResult>;
        },
        DeleteDriverCountryRule(
            variables: Types.DeleteDriverCountryRuleMutationVariables,
            options?: C,
        ): Promise<Types.DeleteDriverCountryRuleMutationOperationResult> {
            return requester<
                Types.DeleteDriverCountryRuleMutationOperationResult,
                Types.DeleteDriverCountryRuleMutationVariables
            >(
                DeleteDriverCountryRuleDocument,
                variables,
                options,
            ) as Promise<Types.DeleteDriverCountryRuleMutationOperationResult>;
        },
        UpdateDriverCountryRule(
            variables: Types.UpdateDriverCountryRuleMutationVariables,
            options?: C,
        ): Promise<Types.UpdateDriverCountryRuleMutationOperationResult> {
            return requester<
                Types.UpdateDriverCountryRuleMutationOperationResult,
                Types.UpdateDriverCountryRuleMutationVariables
            >(
                UpdateDriverCountryRuleDocument,
                variables,
                options,
            ) as Promise<Types.UpdateDriverCountryRuleMutationOperationResult>;
        },
        GetDriverCountryRuleForEdit(
            variables: Types.GetDriverCountryRuleForEditQueryVariables,
            options?: C,
        ): Promise<Types.GetDriverCountryRuleForEditQueryOperationResult> {
            return requester<
                Types.GetDriverCountryRuleForEditQueryOperationResult,
                Types.GetDriverCountryRuleForEditQueryVariables
            >(
                GetDriverCountryRuleForEditDocument,
                variables,
                options,
            ) as Promise<Types.GetDriverCountryRuleForEditQueryOperationResult>;
        },
        ListDriverCountryRulesForTable(
            variables?: Types.ListDriverCountryRulesForTableQueryVariables,
            options?: C,
        ): Promise<Types.ListDriverCountryRulesForTableQueryOperationResult> {
            return requester<
                Types.ListDriverCountryRulesForTableQueryOperationResult,
                Types.ListDriverCountryRulesForTableQueryVariables
            >(
                ListDriverCountryRulesForTableDocument,
                variables,
                options,
            ) as Promise<Types.ListDriverCountryRulesForTableQueryOperationResult>;
        },
        addCrossBorderTripWhitelistEntry(
            variables: Types.AddCrossBorderTripWhitelistEntryMutationVariables,
            options?: C,
        ): Promise<Types.AddCrossBorderTripWhitelistEntryMutationOperationResult> {
            return requester<
                Types.AddCrossBorderTripWhitelistEntryMutationOperationResult,
                Types.AddCrossBorderTripWhitelistEntryMutationVariables
            >(
                AddCrossBorderTripWhitelistEntryDocument,
                variables,
                options,
            ) as Promise<Types.AddCrossBorderTripWhitelistEntryMutationOperationResult>;
        },
        countriesByEnglishName(
            variables?: Types.CountriesByEnglishNameQueryVariables,
            options?: C,
        ): Promise<Types.CountriesByEnglishNameQueryOperationResult> {
            return requester<
                Types.CountriesByEnglishNameQueryOperationResult,
                Types.CountriesByEnglishNameQueryVariables
            >(
                CountriesByEnglishNameDocument,
                variables,
                options,
            ) as Promise<Types.CountriesByEnglishNameQueryOperationResult>;
        },
        crossBorderWhitelist(
            variables?: Types.CrossBorderWhitelistQueryVariables,
            options?: C,
        ): Promise<Types.CrossBorderWhitelistQueryOperationResult> {
            return requester<Types.CrossBorderWhitelistQueryOperationResult, Types.CrossBorderWhitelistQueryVariables>(
                CrossBorderWhitelistDocument,
                variables,
                options,
            ) as Promise<Types.CrossBorderWhitelistQueryOperationResult>;
        },
        removeCrossBorderTripWhitelistEntry(
            variables: Types.RemoveCrossBorderTripWhitelistEntryMutationVariables,
            options?: C,
        ): Promise<Types.RemoveCrossBorderTripWhitelistEntryMutationOperationResult> {
            return requester<
                Types.RemoveCrossBorderTripWhitelistEntryMutationOperationResult,
                Types.RemoveCrossBorderTripWhitelistEntryMutationVariables
            >(
                RemoveCrossBorderTripWhitelistEntryDocument,
                variables,
                options,
            ) as Promise<Types.RemoveCrossBorderTripWhitelistEntryMutationOperationResult>;
        },
        updateCrossBorderTripWhitelistEntry(
            variables: Types.UpdateCrossBorderTripWhitelistEntryMutationVariables,
            options?: C,
        ): Promise<Types.UpdateCrossBorderTripWhitelistEntryMutationOperationResult> {
            return requester<
                Types.UpdateCrossBorderTripWhitelistEntryMutationOperationResult,
                Types.UpdateCrossBorderTripWhitelistEntryMutationVariables
            >(
                UpdateCrossBorderTripWhitelistEntryDocument,
                variables,
                options,
            ) as Promise<Types.UpdateCrossBorderTripWhitelistEntryMutationOperationResult>;
        },
        flexibleOffersGlobalScalarConfig(
            variables?: Types.FlexibleOffersGlobalScalarConfigQueryVariables,
            options?: C,
        ): Promise<Types.FlexibleOffersGlobalScalarConfigQueryOperationResult> {
            return requester<
                Types.FlexibleOffersGlobalScalarConfigQueryOperationResult,
                Types.FlexibleOffersGlobalScalarConfigQueryVariables
            >(
                FlexibleOffersGlobalScalarConfigDocument,
                variables,
                options,
            ) as Promise<Types.FlexibleOffersGlobalScalarConfigQueryOperationResult>;
        },
        updateFOScalarConfigParameter(
            variables: Types.UpdateFoScalarConfigParameterMutationVariables,
            options?: C,
        ): Promise<Types.UpdateFoScalarConfigParameterMutationOperationResult> {
            return requester<
                Types.UpdateFoScalarConfigParameterMutationOperationResult,
                Types.UpdateFoScalarConfigParameterMutationVariables
            >(
                UpdateFoScalarConfigParameterDocument,
                variables,
                options,
            ) as Promise<Types.UpdateFoScalarConfigParameterMutationOperationResult>;
        },
        CancelAssignationOffer(
            variables: Types.CancelAssignationOfferMutationVariables,
            options?: C,
        ): Promise<Types.CancelAssignationOfferMutationOperationResult> {
            return requester<
                Types.CancelAssignationOfferMutationOperationResult,
                Types.CancelAssignationOfferMutationVariables
            >(
                CancelAssignationOfferDocument,
                variables,
                options,
            ) as Promise<Types.CancelAssignationOfferMutationOperationResult>;
        },
        ConfirmAssignationOffersForOrder(
            variables: Types.ConfirmAssignationOffersForOrderMutationVariables,
            options?: C,
        ): Promise<Types.ConfirmAssignationOffersForOrderMutationOperationResult> {
            return requester<
                Types.ConfirmAssignationOffersForOrderMutationOperationResult,
                Types.ConfirmAssignationOffersForOrderMutationVariables
            >(
                ConfirmAssignationOffersForOrderDocument,
                variables,
                options,
            ) as Promise<Types.ConfirmAssignationOffersForOrderMutationOperationResult>;
        },
        CreateAssignationOffersForOrder(
            variables: Types.CreateAssignationOffersForOrderMutationVariables,
            options?: C,
        ): Promise<Types.CreateAssignationOffersForOrderMutationOperationResult> {
            return requester<
                Types.CreateAssignationOffersForOrderMutationOperationResult,
                Types.CreateAssignationOffersForOrderMutationVariables
            >(
                CreateAssignationOffersForOrderDocument,
                variables,
                options,
            ) as Promise<Types.CreateAssignationOffersForOrderMutationOperationResult>;
        },
        CreateAssignationOfferForVehicle(
            variables: Types.CreateAssignationOfferForVehicleMutationVariables,
            options?: C,
        ): Promise<Types.CreateAssignationOfferForVehicleMutationOperationResult> {
            return requester<
                Types.CreateAssignationOfferForVehicleMutationOperationResult,
                Types.CreateAssignationOfferForVehicleMutationVariables
            >(
                CreateAssignationOfferForVehicleDocument,
                variables,
                options,
            ) as Promise<Types.CreateAssignationOfferForVehicleMutationOperationResult>;
        },
        AssignationOffersForOrder(
            variables?: Types.AssignationOffersForOrderQueryVariables,
            options?: C,
        ): Promise<Types.AssignationOffersForOrderQueryOperationResult> {
            return requester<
                Types.AssignationOffersForOrderQueryOperationResult,
                Types.AssignationOffersForOrderQueryVariables
            >(
                AssignationOffersForOrderDocument,
                variables,
                options,
            ) as Promise<Types.AssignationOffersForOrderQueryOperationResult>;
        },
        UpdateRide(
            variables: Types.UpdateRideMutationVariables,
            options?: C,
        ): Promise<Types.UpdateRideMutationOperationResult> {
            return requester<Types.UpdateRideMutationOperationResult, Types.UpdateRideMutationVariables>(
                UpdateRideDocument,
                variables,
                options,
            ) as Promise<Types.UpdateRideMutationOperationResult>;
        },
    };
}
export type Sdk = ReturnType<typeof getSdk>;
