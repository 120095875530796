import type { VehicleType } from "@daytrip/legacy-enums";
import { VehicleTypesCapacity } from "@daytrip/legacy-enums";
import type { Passenger } from "@daytrip/legacy-models";

export const getRequiredCapacityForVehicle = ({
    vehicleType,
    order,
    vehiclePassengersCount,
}: {
    vehicleType: VehicleType;
    order: { passengers: Pick<Passenger, "luggage">[]; vehicles: VehicleType[] };
    vehiclePassengersCount: number;
}) => {
    const { passengers, vehicles } = order;
    const orderAdditionalLuggage = getAdditionalLuggageCountForOrder(order);

    if (orderAdditionalLuggage === 0 || vehicles.length === 1) {
        return vehiclePassengersCount + orderAdditionalLuggage;
    }

    // To simplify the suitability for order with multiple vehicles that have additional luggage (~0.2% of all orders),
    // we always require full capacity from all of them.
    // It is adding additional safety for tricky orders with multiple vehicles.
    // Especially considering the fact that many vans have relatively small trunk.
    const passengersAndLuggageToPlace = orderAdditionalLuggage + passengers.length;
    const sumOfCapacity = vehicles.reduce((acc, v) => acc + VehicleTypesCapacity[v], 0);
    const remainingCapacity = sumOfCapacity - passengersAndLuggageToPlace;

    // Support is sometimes hacking the system and adding more luggage against the recommended configuration.
    // Example: forcing Tesla model X for some luxury orders in the USA.
    const capacityAboveLimit = remainingCapacity < 0 ? remainingCapacity * -1 : 0;
    const capacityAboveLimitPerVehicle = Math.ceil(capacityAboveLimit / vehicles.length);

    return VehicleTypesCapacity[vehicleType] + capacityAboveLimitPerVehicle;
};

export const getAdditionalLuggageCountForOrder = (order: { passengers: Pick<Passenger, "luggage">[] }) => {
    // The luggage is assigned in pairs so we subtract its count by 2.
    return order.passengers.reduce((acc, p) => acc + Math.ceil(p.luggage.length / 2), 0) - order.passengers.length;
};
