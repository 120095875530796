import { AUTH_JWT_REFRESH_RATE_DENOMINATOR, AUTH_REFRESH_JWT_REFRESH_RATE_DENOMINATOR } from "@daytrip/legacy-config";
import { decodeJwt } from "jose";


export type UserJWTOptions = {
    iat: number;
    exp: number;
};

export const isTokenExpired = (token: string, expirationThresholdMS: number = 0): boolean => {
    const tokenContent: UserJWTOptions | null = decodeJwt(token) as UserJWTOptions;

    const tokenExpiration = tokenContent?.exp ?? 0;
    return tokenExpiration * 1000 - Date.now() <= expirationThresholdMS;
};

export const shallAuthTokenBeRefreshed = (token: string, defaultExpiration: number): boolean =>
    isTokenExpired(token, Math.ceil(defaultExpiration / AUTH_JWT_REFRESH_RATE_DENOMINATOR));

export const shallAuthRefreshTokenBeRefreshed = (token: string, defaultExpiration: number): boolean =>
    isTokenExpired(token, Math.ceil(defaultExpiration / AUTH_REFRESH_JWT_REFRESH_RATE_DENOMINATOR));
