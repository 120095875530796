import type { HostAgencyAndTravelAgentCount } from "@daytrip/legacy-models";
import { SimpleTravelAgent } from "@legacy/domain/SimpleTravelAgent";
import type { SimpleUser } from "@legacy/domain/SimpleUser";
import type { Country } from "@legacy/models/Country";
import type { TravelAgent } from "@legacy/models/TravelAgent";
import type { RetrieveTravelAgentsOptions } from "@legacy/options/RetrieveTravelAgentsOptions";
import autobind from "autobind-decorator";
import { action, observable } from "mobx";

import { PageStore } from "../../stores/PageStore";

import type { TravelAgentsPageRouter } from "./TravelAgentsPageRouter";

@autobind
export class TravelAgentsPageStore extends PageStore<TravelAgentsPageRouter, {}> {
    @observable
    public travelAgents?: Array<SimpleTravelAgent>;

    @observable
    public editedTravelAgentModel?: SimpleTravelAgent;

    @observable
    public travelAgentsCount: number = 0;

    @observable
    public owners?: Array<SimpleUser>;

    @observable
    public countries?: Array<Country>;

    @observable
    public hostAgencies?: HostAgencyAndTravelAgentCount[];

    @action
    public async onFetchData() {
        await this.fetchContent();
    }

    @action
    public async fetchContent() {
        this.travelAgents = undefined;

        const options = {
            skip: this.pageRouter.skip,
            limit: this.pageRouter.limit,
            sortBy: this.pageRouter.sortBy,
            sortDirection: this.pageRouter.sortDirection,
            search: this.pageRouter.search,
            statusIn: this.pageRouter.statusIn,
            ownerIds: this.pageRouter.ownerIds,
            countryIds: this.pageRouter.countryIds,
            hostAgencyIds: this.pageRouter.hostAgencyIds,
            partnerBookingAvailable: this.pageRouter.partnerBookingAvailable,
            includeTravelAgentsWithoutCountry: this.pageRouter.includeTravelAgentsWithoutCountry,
            travelAgentCategory: this.pageRouter.travelAgentCategories,
        } as RetrieveTravelAgentsOptions;

        let hostAgenciesPaginated;

        [this.travelAgents, this.travelAgentsCount, this.owners, this.countries, hostAgenciesPaginated] =
            await Promise.all([
                this.rpcClient.travelAgent.retrieveTravelAgents(options),
                this.rpcClient.travelAgent.retrieveTravelAgentsCount(options),
                this.rpcClient.travelAgent.retrieveTravelAgentsOwners(),
                this.rpcClient.content.retrieveCountries({}),
                this.rpcClient.hostAgency.retrieveHostAgenciesAndTravelAgentCount({}),
            ]);

        this.hostAgencies = hostAgenciesPaginated.data;
    }

    @action
    public async approveTravelAgent(id: string) {
        await this.rpcClient.travelAgent.approveTravelAgent(id);

        try {
            await this.rpcClient.klaviyo.createNewProfile(id);
        } catch (err) {
            alert("Failed to create Klaviyo profile for the travel agent.");
        }
        this.fetchContent();
    }

    @action
    public async declineTravelAgent(id: string, reason: string) {
        await this.rpcClient.travelAgent.declineTravelAgent(id, reason);
        this.fetchContent();
    }

    @action
    public async deleteTravelAgent(id: string) {
        await this.rpcClient.travelAgent.deleteTravelAgent(id);
        this.fetchContent();
    }

    public isDataFetched(): this is TravelAgentsPageStore & TravelAgentsPageStoreDataFetched {
        return this.travelAgents !== undefined && this.owners !== undefined;
    }
}

export interface TravelAgentsPageStoreDataFetched {
    travelAgents: Array<TravelAgent>;
    owners: Array<SimpleUser>;
}
