import type { ApolloError } from "@apollo/client";
import { action, observable } from "mobx";

import { getApolloSdk } from "../../apolloClient/apolloClient";
import { ObservableGqlQuery } from "../../apolloClient/ObservableGqlQuery";
import { PageStore } from "../../stores/PageStore";
import { AssignationOffersForOverviewQueryVariables } from "../../types/generated/graphql-types";
import type { AssignationOfferForOverviewFragment } from "../../types/generated/graphql-types";

import type { AssignationOffersPageRouter } from "./AssignationOffersPageRouter";

export class AssignationOffersPageStore extends PageStore<AssignationOffersPageRouter> {
    @observable
    assignationOffersQuery = new ObservableGqlQuery(getApolloSdk().AssignationOffersForOverview);

    @observable
    assignationOffersTotalCount: number | undefined = undefined;

    @observable
    assignationOffers: AssignationOfferForOverviewFragment[] | undefined = [];

    @observable
    public loading = false;

    @observable
    public error: ApolloError | undefined;

    private extractQueryVariables({
        offset,
        limit,
        pricingCountryIds,
        orderStatuses,
        statuses,
        sortBy,
        sortDirection,
    }: AssignationOffersForOverviewQueryVariables) {
        const queryVariables: AssignationOffersForOverviewQueryVariables = {};
        if (offset) {
            queryVariables.offset = offset - 1;
        }
        if (limit) {
            queryVariables.limit = queryVariables.offset ? limit - queryVariables.offset : limit;
        }
        if (sortBy) {
            queryVariables.sortBy = sortBy;
        }
        if (sortDirection) {
            queryVariables.sortDirection = sortDirection;
        }
        if (pricingCountryIds) {
            queryVariables.pricingCountryIds = pricingCountryIds;
        }
        if (orderStatuses) {
            queryVariables.orderStatuses = orderStatuses;
        }
        if (statuses) {
            queryVariables.statuses = statuses;
        }

        return queryVariables;
    }

    @action
    public async fetchAssignationOffers(queryParams: AssignationOffersForOverviewQueryVariables) {
        const extractedQueryVariables = this.extractQueryVariables(queryParams);
        this.loading = true;
        let data;
        try {
            data = await this.assignationOffersQuery.execute(extractedQueryVariables);
        } catch (error) {
            this.error = error;
        }
        this.assignationOffers = data?.assignationOffersForOverview.data;
        this.assignationOffersTotalCount = data?.assignationOffersForOverview.totalCount;
        this.loading = false;
    }
}
