import { OrderContentLocation } from "@daytrip/legacy-models";
import { Exclude, Expose } from "class-transformer";
import { IsString } from "class-validator";


@Exclude()
export class OrderContentLocationForAssignation extends OrderContentLocation {
    @IsString()
    @Expose()
    public name: string;
}
