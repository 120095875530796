import type { ServerError, ServerParseError } from "@apollo/client";
import { fromPromise } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import ms from "ms";

import { AUTH_REFRESH_JWT_EXPIRES_IN } from "../../config.management";
import { globalManagementLogger } from "../../global-logger";
import { refreshTokens } from "../../operations/authentication/refreshTokens.operation";
import {
    getManagementRefreshToken,
    getManagementAuthenticationToken,
    setManagementRefreshToken,
    setManagementAuthenticationToken,
} from "../../utils/authentication/authenticationUtils";
import { GQLERROR_TOKEN_EXPIRED } from "../../utils/graphql/getGraphQLErrorCode";

let isRefreshing = false;
let pendingRequests = [] as any[];

const resolvePendingRequests = () => {
    pendingRequests.forEach((callback) => callback());
    pendingRequests = [];
};

const onNetworkError = (networkError: Error | ServerError | ServerParseError) => {
    console.warn(`[Network error]: ${networkError}`);
};
const refreshDefaultExpiresIn = AUTH_REFRESH_JWT_EXPIRES_IN ? ms(AUTH_REFRESH_JWT_EXPIRES_IN) : 0;

export const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
    if (!graphQLErrors && !networkError) {
        return;
    }

    globalManagementLogger.info("errorLink");
    // todo add server log here
    if (networkError) {
        return onNetworkError(networkError);
    }
    if (!graphQLErrors) {
        return;
    }
    // eslint-disable-next-line no-restricted-syntax
    for (const gqlError of graphQLErrors) {
        console.log("err code: ", gqlError.extensions?.code);
        switch (gqlError.extensions?.code) {
            case GQLERROR_TOKEN_EXPIRED: {
                const refresh = getManagementRefreshToken();
                const authentication = getManagementAuthenticationToken();
                const currentTokens = { refresh, authentication };

                // eslint-disable-next-line no-case-declarations
                let forward$;

                if (!isRefreshing && currentTokens) {
                    // TODO: what exactly is this?
                    isRefreshing = true;

                    // replace authentication token with refresh token
                    forward$ = fromPromise(
                        refreshTokens(refreshDefaultExpiresIn)
                            // eslint-disable-next-line no-loop-func
                            .then(({ data }) => {
                                const refreshedTokens = data?.refreshTokens;
                                if (!refreshedTokens) {
                                    throw Error("Received invalid tokens after refreshing tokens");
                                }

                                // set tokens
                                setManagementRefreshToken(refreshedTokens.refresh ?? refresh!);
                                setManagementAuthenticationToken(refreshedTokens.authentication);

                                // Store the new tokens for your auth link
                                resolvePendingRequests();
                                return refreshedTokens.authentication;
                            })
                            // eslint-disable-next-line no-loop-func
                            .catch((error) => {
                                console.log("clear tokens"); // TODO: really?

                                pendingRequests = [];
                                throw error;
                            })
                            // eslint-disable-next-line no-loop-func
                            .finally(() => {
                                isRefreshing = false;
                            }),
                    ).filter((value) => Boolean(value));
                } else {
                    // Will only emit once the Promise is resolved
                    forward$ = fromPromise(
                        // eslint-disable-next-line no-loop-func
                        new Promise((resolve) => {
                            // @ts-ignore
                            pendingRequests.push(() => resolve());
                        }),
                    );
                }

                return forward$.flatMap(() => forward(operation));
            }
            default:
                console.warn(
                    `[GraphQL error]: Message: ${gqlError.message}, Location: ${JSON.stringify(
                        gqlError.locations,
                    )}, Path: ${gqlError.path}`,
                );
        }
    }
});
