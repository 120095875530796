import type { Ride } from "@daytrip/legacy-models";
import type { IPoolAvailabilityConfig } from "@daytrip/pool-client";

export interface PoolAvailabilityConfigOverviewsForTimespanProps {
    offset?: number;
    limit?: number;
    sortBy?: string;
    sortDirection?: number;
    departureAtFrom?: string;
    departureAtTo?: string;
}

export class PoolAvailabilityConfigOverview implements IPoolAvailabilityConfig {
    customDropoff1: string;

    customDropoff2: string;

    customDropoff3: string;

    customPickup1: string;

    customPickup2: string;

    customPickup3: string;

    cutoffTime: string;

    cutoffTimeOverride: string;

    cutoffTimeWithChildren: string;

    date: string;

    destination: string;

    id: string;

    isOtherDirection: string;

    masterTimeEarliest: string;

    masterTimeLatest: string;

    origin: string;

    price1: string;

    price2: string;

    price3: string;

    routeId: string;

    masterRouteId: string;

    timeFrom: string;

    totalVehicles: string;

    seatsPerVehicle: string;

    vehicleList: number[];

    rides: Ride[] = [];
}
export interface PoolAvailabilityConfigDetails extends PoolAvailabilityConfigOverview {
    journeyDetails: null;
}
