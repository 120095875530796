/* eslint-disable no-alert */
import { Currency } from "@daytrip/legacy-enums";
import type { HostAgency } from "@daytrip/legacy-models";
import { transformValidationErrorsToArrayString } from "@daytrip/legacy-transformers";
import { evaluateTravelAgentCategory } from "@daytrip/legacy-utils";
import { isUndefinedOrNull } from "@daytrip/utils";
import { transformTravelAgentRealizedOrdersToTier } from "@daytrip/utils";
import { OrderDataForOrdersPage } from "@legacy/dataTransferObjects/OrderDataForOrdersPage";
import type { SimpleCountry } from "@legacy/domain/SimpleCountry";
import { SimpleUser } from "@legacy/domain/SimpleUser";
import type { AutomaticEmailAttempt } from "@legacy/models/AutomaticEmailAttempt";
import { Chargeback } from "@legacy/models/Chargeback";
import { MangopayInformation } from "@legacy/models/MangopayInformation";
import { Payment } from "@legacy/models/Payment";
import { PaymentRequest } from "@legacy/models/PaymentRequest";
import { ReferralCode } from "@legacy/models/ReferralCode";
import { TravelAgent } from "@legacy/models/TravelAgent";
import { TravelAgentChangeLog } from "@legacy/models/TravelAgentChangeLog";
import { User } from "@legacy/models/User";
import type { RetrieveChargebacksOptions } from "@legacy/options/RetrieveChargebacksOptions";
import { RetrieveOrdersOptions } from "@legacy/options/RetrieveOrdersOptions";
import type { RetrievePaymentRequestsOptions } from "@legacy/options/RetrievePaymentRequestsOptions";
import autobind from "autobind-decorator";
import { classToPlain, plainToClass } from "class-transformer";
import type { ValidationError } from "class-validator";
import { validate } from "class-validator";
import { action, computed, extendObservable, observable, toJS } from "mobx";

import { globalManagementLogger } from "../../global-logger";
import { ChargebackOperator } from "../../operators/ChargebackOperator";
import { PaymentOperator } from "../../operators/PaymentOperator";
import { PaymentRequestOperator } from "../../operators/PaymentRequestOperator";
import { PageStore } from "../../stores/PageStore";
import { PaginatedDataStore } from "../../stores/PaginatedDataStore";
import {
    getPhoneNumberExtendedValidationMessage,
    validatePhoneNumberWithAlert,
} from "../../utils/validateUserPhoneNumber";

import type { TravelAgentPageRouter } from "./TravelAgentPageRouter";

@autobind
export class TravelAgentPageStore extends PageStore<TravelAgentPageRouter, {}> {
    @observable
    public automaticEmailAttempts: Array<AutomaticEmailAttempt> = [];

    @observable
    public user: User | undefined;

    @observable
    public editedUser: User | undefined;

    @observable
    public countries: Array<SimpleCountry> | undefined;

    @observable
    public hostAgencies: Array<HostAgency> = [];

    @observable
    public orders?: PaginatedDataStore<RetrieveOrdersOptions, OrderDataForOrdersPage>;

    @observable
    public apiPartnerOrders?: PaginatedDataStore<RetrieveOrdersOptions, OrderDataForOrdersPage>;

    @observable
    public payments?: Array<Payment>;

    @observable
    public paymentOperators: Array<PaymentOperator> = [];

    @observable
    public paymentRequestOperators: Array<PaymentRequestOperator> = [];

    @observable
    public paymentRequestOperator?: PaymentRequestOperator;

    @observable
    public chargebackOperators: Array<ChargebackOperator> = [];

    @observable
    public editedUserValidationErrors: Array<ValidationError> = [];

    @observable
    public existingUser?: User;

    @observable
    public isPaymentRequestFormVisible: boolean = false;

    @observable
    public referralCode?: ReferralCode;

    @observable
    public users?: Array<SimpleUser>;

    @action
    public async onFetchData() {
        await this.fetchContent();
    }

    @action
    public async fetchContent() {
        const { userId } = this.pageRouter;

        this.user = undefined;
        this.editedUser = undefined;

        if (this.user && (this.user as User)._id !== userId) {
            this.orders = undefined;
            this.apiPartnerOrders = undefined;
        }

        if (userId) {
            const user = await this.rpcClient.user.retrieveUser(userId, true);
            user.mangopayInformation = plainToClass(MangopayInformation, user.mangopayInformation);
            this.user = user;

            if (!this.user.travelAgent) {
                this.userEdit();
            }
        } else {
            this.user = classToPlain(new User()) as User;
            this.userEdit();
        }

        if (userId) {
            await this.fetchOrders(userId);

            if (this.user.travelAgent?.ownerId) {
                this.fetchTravelAgentOwner(this.user.travelAgent.ownerId);
            }

            this.fetchPaymentInformation();
        }

        this.fetchCountries();
        this.fetchHostAgencies();

        if (userId) {
            this.fetchReferralCode(userId);
        }
    }

    @action
    public fetchOrders(userId: string) {
        if (!this.orders) {
            this.orders = new PaginatedDataStore(
                OrderDataForOrdersPage,
                RetrieveOrdersOptions,
                this.rpcClient.order.retrieveOrdersDataForOrdersPage,
                this.rpcClient.order.retrieveOrdersCount,
                this.pageRouter,
                "orders_",
                {
                    skip: 1,
                    limit: 30,
                    userIds: [userId],
                    sortBy: "departureAt",
                    sortDirection: -1,
                },
            );
        } else {
            this.orders.fetch();
        }
    }

    @action
    public fetchApiPartnerOrders(userId: string) {
        if (!this.apiPartnerOrders) {
            this.apiPartnerOrders = new PaginatedDataStore(
                OrderDataForOrdersPage,
                RetrieveOrdersOptions,
                this.rpcClient.order.retrieveOrdersDataForOrdersPage,
                this.rpcClient.order.retrieveOrdersCount,
                this.pageRouter,
                "affiliateOrders_",
                {
                    skip: 1,
                    limit: 30,
                    apiPartnerIds: [userId],
                    sortBy: "departureAt",
                    sortDirection: -1,
                },
            );
        } else {
            this.apiPartnerOrders.fetch();
        }
    }

    @action
    public async fetchTravelAgentOwner(ownerId: string) {
        const [travelAgentOwner] = await this.rpcClient.user.retrieveSimpleUsers({ userIds: [ownerId] });

        if (travelAgentOwner) {
            this.travelAgentOwner = travelAgentOwner;
            this.users = [this.travelAgentOwner];
        } else {
            this.travelAgentOwner = new SimpleUser();
            this.travelAgentOwner._id = this.user?.travelAgent?.ownerId || "";
            this.travelAgentOwner.fullName = "Unknown";
        }
    }

    @action
    public async fetchCountries() {
        const countries = await this.rpcClient.content.retrieveSimpleCountries({});
        this.countries = countries.sort((a, b) => a.englishName.localeCompare(b.englishName));
    }

    @action
    public async fetchHostAgencies() {
        const hostAgenciesData = await this.rpcClient.hostAgency.retrieveHostAgencies();
        this.hostAgencies = hostAgenciesData;
    }

    @action
    public async fetchReferralCode(userId: string) {
        if (!userId) {
            this.referralCode = undefined;
            return;
        }

        try {
            this.referralCode = await this.rpcClient.referral.retrieveReferralCodeByUserId(userId);
        } catch (e: any) {
            this.referralCode = undefined;
        }
    }

    @action
    public async fetchPaymentInformation(): Promise<void> {
        if (!this.user) {
            return;
        }

        const { validationInfo, payments: paymentEntities } =
            await this.rpcClient.payment.retrievePaymentsWithValidationInfo({ userIds: [this.user._id] });
        const payments = plainToClass(Payment, paymentEntities);

        let chargebackOperators: Array<ChargebackOperator> = [];

        if (this.orders && this.orders.data && this.orders.data.length > 0) {
            const chargebacksOptions = {
                orderIdsOrNotConnected: this.orders.data.map((o) => o.simpleOrder.orderId),
            } as RetrieveChargebacksOptions;

            const chargebacks = plainToClass(
                Chargeback,
                await this.rpcClient.payment.retrieveChargebacks(chargebacksOptions),
            );

            if (chargebacks) {
                chargebackOperators = chargebacks.map(
                    (cb) =>
                        new ChargebackOperator({
                            modelConstructor: Chargeback,
                            model: cb,
                            modules: null,
                            data: null,
                        }),
                );
            }
        }

        const paymentRequestsOptions = {} as RetrievePaymentRequestsOptions;
        paymentRequestsOptions.userIds = [this.user._id];
        const paymentRequests = plainToClass(
            PaymentRequest,
            await this.rpcClient.payment.retrievePaymentRequests(paymentRequestsOptions),
        );
        if (paymentRequests) {
            this.paymentRequestOperators = [];
            this.paymentOperators = [];
            paymentRequests.forEach(async (pr) => {
                // trying to find fulfilled payment
                let payment = payments.find((p) => p.paymentRequestId === pr._id && !!p.fulfilledAt);
                if (!payment) {
                    // if no fulfilled payment found - take any
                    payment = payments.find((p) => p.paymentRequestId === pr._id);
                }

                if (!payment) {
                    this.paymentRequestOperators.push(
                        new PaymentRequestOperator({
                            modelConstructor: PaymentRequest,
                            model: pr,
                            modules: null,
                            data: null,
                        }),
                    );
                } else {
                    let isChargebackEnabled = false;

                    try {
                        isChargebackEnabled = !!validationInfo?.[payment._id];
                    } catch (e: any) {
                        globalManagementLogger.error(e);
                    }

                    this.paymentOperators.push(
                        new PaymentOperator({
                            modelConstructor: Payment,
                            model: payment,
                            paymentRequest: pr,
                            modules: null,
                            data: {
                                isChargebackEnabled,
                            },
                            chargebacks: chargebackOperators.filter(
                                (ch) => ch.m.paymentId === pr._id || (payment && ch.m.paymentId === payment._id),
                            ),
                            onUpdate: this.fetchPaymentInformation,
                        }),
                    );
                }
            });

            const paymentOperatorsWithoutPaymentRequest: Array<PaymentOperator> = [];

            // eslint-disable-next-line no-restricted-syntax
            for (const payment of payments.filter((p) => !p.paymentRequestId)) {
                let isChargebackEnabled = false;

                try {
                    isChargebackEnabled = !!validationInfo?.[payment._id];
                } catch (e: any) {
                    globalManagementLogger.error(e);
                }

                const po = new PaymentOperator({
                    modelConstructor: Payment,
                    model: payment,
                    modules: null,
                    data: {
                        isChargebackEnabled,
                    },
                    chargebacks: chargebackOperators.filter((ch) => ch.m.paymentId === payment._id),
                    onUpdate: this.fetchPaymentInformation,
                });

                paymentOperatorsWithoutPaymentRequest.push(po);
            }

            this.paymentOperators = this.paymentOperators.concat(paymentOperatorsWithoutPaymentRequest);
        }

        const paymentRequest = new PaymentRequest();
        paymentRequest.userId = this.user._id;

        const paymentRequestOperator = new PaymentRequestOperator({
            modelConstructor: PaymentRequest,
            model: paymentRequest,
            modules: null,
            data: null,
            validateOptions: { skipMissingProperties: true },
            onSave: async (model: PaymentRequest) => {
                await this.rpcClient.payment.createPaymentRequest(toJS(model));

                this.fetchPaymentInformation();

                this.paymentRequestOperator!.edit((pr) => {
                    pr.description = "";
                    pr.amount = 0;
                });
            },
        });

        this.paymentRequestOperator = paymentRequestOperator;
        this.chargebackOperators = chargebackOperators.filter((cho) => !cho.model.paymentId);
    }

    @action
    public userUpdateTravelAgentDiscountCoefficient(value: number) {
        const travelAgent = (this.editedUser as User).travelAgent as TravelAgent;
        travelAgent.discountCoefficient = value;
        travelAgent.category = evaluateTravelAgentCategory(travelAgent, this.selectedHostAgency);
    }

    @action
    public userUpdateTravelAgentCommission(value: number) {
        const travelAgent = (this.editedUser as User).travelAgent as TravelAgent;
        travelAgent.commissionPercentage = value;
        travelAgent.category = evaluateTravelAgentCategory(travelAgent, this.selectedHostAgency);
    }

    @action
    public userUpdateTravelAgentCurrency(value: Currency) {
        ((this.editedUser as User).travelAgent as TravelAgent).currency = value;
    }

    @action
    public userUpdateTravelAgentId(value: string) {
        ((this.editedUser as User).travelAgent as TravelAgent).agentId = value;
    }

    @action
    public userUpdateFirstName(value: string) {
        (this.editedUser as User).firstName = value;
    }

    @computed
    public get firstNameValidationMessage(): string | undefined {
        const validationErrors = this.editedUserValidationErrors.find((ve) => ve.property === "firstName");
        if (!validationErrors) {
            return undefined;
        }
        return JSON.stringify(validationErrors.constraints);
    }

    @action
    public userUpdateLastName(value: string) {
        (this.editedUser as User).lastName = value;
    }

    @computed
    public get lastNameValidationMessage(): string | undefined {
        const validationErrors = this.editedUserValidationErrors.find((ve) => ve.property === "lastName");
        if (!validationErrors) {
            return undefined;
        }
        return JSON.stringify(validationErrors.constraints);
    }

    @action
    public userUpdateEmail(value: string) {
        (this.editedUser as User).email = value.toLowerCase();
        this.existingUser = undefined;
    }

    @computed
    public get emailValidationMessage(): string | undefined {
        const validationErrors = this.editedUserValidationErrors.find((ve) => ve.property === "email");
        if (!validationErrors) {
            return undefined;
        }
        return JSON.stringify(validationErrors.constraints);
    }

    @action
    public userUpdateOtherEmail(index: number, value: string) {
        (this.editedUser as User).otherEmails[index] = value;
    }

    @computed
    public get otherEmailsValidationMessage(): string | undefined {
        const validationErrors = this.editedUserValidationErrors.find((ve) => ve.property === "otherEmails");
        if (!validationErrors) {
            return undefined;
        }
        return JSON.stringify(validationErrors.constraints);
    }

    @action
    public userRemoveOtherEmail(index: number) {
        (this.editedUser as User).otherEmails.splice(index, 1);
    }

    @action
    public userAddOtherEmail(value: string) {
        (this.editedUser as User).otherEmails.push(value);
    }

    @action
    public userUpdatePaypalEmail(value: string) {
        (this.editedUser as User).paypalEmail = value.toLowerCase();
        this.existingUser = undefined;
    }

    @computed
    public get paypalEmailValidationMessage(): string | undefined {
        const validationErrors = this.editedUserValidationErrors.find((ve) => ve.property === "paypalEmail");
        if (!validationErrors) {
            return undefined;
        }
        return JSON.stringify(validationErrors.constraints);
    }

    @action
    public userUpdatePhoneNumber(value: string) {
        (this.editedUser as User).phoneNumber = value;
    }

    @computed
    public get phoneNumberValidationMessage(): string | undefined {
        const validationErrors = this.editedUserValidationErrors.find((ve) => ve.property === "phoneNumber");
        const validationMessages = validationErrors?.constraints
            ? JSON.stringify(validationErrors.constraints)
            : undefined;

        return getPhoneNumberExtendedValidationMessage(
            ((this.editedUser ?? this.user) as User).phoneNumber,
            validationMessages,
            Boolean(this.user?.travelAgent),
        );
    }

    @computed
    public get selectedHostAgency(): HostAgency | undefined {
        if (this.hostAgencies && this.editedUser?.travelAgent?.hostAgencyId) {
            return this.hostAgencies.find(
                (hostAgency) => hostAgency._id === this.editedUser?.travelAgent?.hostAgencyId,
            );
        }
    }

    @action
    public userUpdateCustomerNote(value: string) {
        this.editedUser!.customerNote = value;
    }

    @action
    public userUpdateWhatsappNumber(value: string) {
        this.editedUser!.whatsappNumber = value;
    }

    @action
    public travelAgentUpdateHostAgency(value: string) {
        const editedUser = this.editedUser?.travelAgent;

        if (!editedUser) {
            return;
        }

        if (value === undefined) {
            editedUser.hostAgencyId = value;
            editedUser.category = evaluateTravelAgentCategory(editedUser, undefined);
            return;
        }

        if (this.hostAgencies) {
            const selectedHostAgency = this.hostAgencies.find((ha) => ha._id === value);
            if (selectedHostAgency) {
                editedUser.ownerId = selectedHostAgency.ownerId;
                editedUser.discountCoefficient = selectedHostAgency.discountCoefficient;
                editedUser.commissionPercentage = selectedHostAgency.commissionPercentage;
                editedUser.partnerBookingAvailable = selectedHostAgency.partnerBookingAvailable;
                editedUser.agentId = selectedHostAgency._id;
                editedUser.hostAgencyId = value;
                editedUser.category = evaluateTravelAgentCategory(editedUser, selectedHostAgency);
                editedUser.currency = selectedHostAgency.currency;
            }
        }
    }

    @computed
    public get whatsappNumberValidationMessage(): string | undefined {
        const validationErrors = this.editedUserValidationErrors.find((ve) => ve.property === "whatsupNumber");
        if (!validationErrors) {
            return undefined;
        }
        return JSON.stringify(validationErrors.constraints);
    }

    @action
    public userUpdateOtherPhoneNumber(index: number, value: string) {
        (this.editedUser as User).otherPhoneNumbers[index] = value;
    }

    @computed
    public get otherPhoneNumbersValidationMessage(): string | undefined {
        const validationErrors = this.editedUserValidationErrors.find((ve) => ve.property === "otherPhoneNumbers");
        if (!validationErrors) {
            return undefined;
        }
        return JSON.stringify(validationErrors.constraints);
    }

    @action
    public userRemoveOtherPhoneNumber(index: number) {
        (this.editedUser as User).otherPhoneNumbers.splice(index, 1);
    }

    @action
    public userAddOtherPhoneNumber(value: string) {
        (this.editedUser as User).otherPhoneNumbers.push(value);
    }

    @action
    public userUpdateCountryId(value: string) {
        (this.editedUser as User).countryId = value;
    }

    @computed
    public get countryIdValidationMessage(): string | undefined {
        const validationErrors = this.editedUserValidationErrors.find((ve) => ve.property === "countryId");
        if (!validationErrors) {
            return undefined;
        }
        return JSON.stringify(validationErrors.constraints);
    }

    @action
    public userEdit() {
        this.editedUser = toJS(this.user);

        if (!this.user?.travelAgent) {
            extendObservable(this.editedUser as User, { travelAgent: classToPlain(new TravelAgent()) as TravelAgent });
        }
    }

    @action
    public userEditCancelOrGoBack() {
        if (!this.pageRouter.userId) {
            window.history.back();
        } else {
            this.editedUser = undefined;
        }
    }

    @action
    public async userValidate() {
        const errorBothDiscountAndCommission =
            (this.editedUser?.travelAgent?.discountCoefficient ?? 0) > 0 &&
            (this.editedUser?.travelAgent?.commissionPercentage ?? 0) > 0 &&
            this.editedUser?.travelAgent?.hostAgencyId !== "INTRAIL";

        if (errorBothDiscountAndCommission) {
            alert("Only INTRAIL can have both discount and commission");
            return false;
        }

        const newUser = plainToClass(User, toJS(this.editedUser));
        this.editedUserValidationErrors = await validate(newUser, {
            skipMissingProperties: true,
        });

        return true;
    }

    @action
    public async userEditSave() {
        if (!this.editedUser) {
            return;
        }

        if (!(await this.userValidate())) {
            return;
        }

        if (this.editedUserValidationErrors.length > 0) {
            globalManagementLogger.info({ payload: toJS(this.editedUserValidationErrors) });

            const validationMessages = transformValidationErrorsToArrayString(this.editedUserValidationErrors).join(
                "\n",
            );
            alert(`Oh, something is wrong. :(\n\nValidation errors:\n${validationMessages}`);
            return;
        }

        if (
            !validatePhoneNumberWithAlert(
                ((this.editedUser ?? this.user) as User).phoneNumber,
                Boolean(this.user?.travelAgent),
            )
        ) {
            return;
        }

        if (this.user && this.editedUser) {
            await this.rpcClient.user.updateUser(this.editedUser._id, this.editedUser);

            if (!isUndefinedOrNull(this.editedUser.version)) {
                this.editedUser.version += 1;
            } else {
                this.editedUser.version = 0;
            }

            if (
                this.editedUser.travelAgent &&
                (!this.user.travelAgent ||
                    (this.user.travelAgent && this.user.travelAgent.ownerId !== this.editedUser.travelAgent.ownerId))
            ) {
                this.travelAgentOwner = (
                    await this.rpcClient.user.retrieveSimpleUsers({
                        userIds: [this.editedUser.travelAgent.ownerId as string],
                    })
                )[0] as SimpleUser;
                this.users = [this.travelAgentOwner];
            }
            this.user = toJS(this.editedUser);
            this.editedUser = undefined;
        } else {
            this.editedUser._id = await this.rpcClient.user.createUser(this.editedUser);
            this.pageRouter.openCreatedUser(this.editedUser._id);
        }
    }

    public async deleteUser() {
        try {
            await this.rpcClient.user.deleteUser((this.user as User)._id);
            this.pageRouter.openUsersAfterDeletion();
        } catch (e: any) {
            alert("Something went wrong during deletion.");
            globalManagementLogger.error(e);
        }
    }

    public async checkIsPossibleUserDuplicate(): Promise<boolean> {
        const isEmailUpdated = this.user?.email !== this.editedUser?.email;

        if (this.editedUser && this.editedUser.email && isEmailUpdated) {
            const users = await this.rpcClient.user.retrieveUsers({ email: this.editedUser.email });

            const [existingUser] = users;
            this.existingUser = existingUser;

            return !!existingUser;
        }
        return false;
    }

    @action
    public async userCreate() {
        if (!this.editedUser) {
            return;
        }

        const isUserAlreadyExist = await this.checkIsPossibleUserDuplicate();
        if (isUserAlreadyExist) {
            return;
        }

        if (!(await this.userValidate())) {
            return;
        }

        if (this.editedUserValidationErrors.length > 0) {
            globalManagementLogger.info({ payload: toJS(this.editedUserValidationErrors) });

            const validationMessages = transformValidationErrorsToArrayString(this.editedUserValidationErrors).join(
                "\n",
            );
            alert(`Oh, something is wrong. :(\n\nValidation errors:\n${validationMessages}`);
            return;
        }

        if (!validatePhoneNumberWithAlert(this.editedUser.phoneNumber, Boolean(this.user?.travelAgent))) {
            return;
        }

        const userId = await this.rpcClient.user.createUser(this.editedUser as User);
        this.user = this.editedUser;
        this.editedUser = undefined;
        this.pageRouter.openCreatedUser(userId);
    }

    public goToExistingUser() {
        if (this.existingUser) {
            this.pageRouter.goToExistingUser(this.existingUser);
        }
    }

    @observable
    public isReferralSending = false;

    @action
    public async sendInitialReferral() {
        this.isReferralSending = true;

        await this.rpcClient.referral.sendReferralInitialEmail((this.user as User)._id);
        this.referralCode = await this.rpcClient.referral.retrieveReferralCodeByUserId((this.user as User)._id);

        this.isReferralSending = false;
    }

    @action
    public setTravelAgent() {
        this.userEdit();
        extendObservable(this.editedUser as User, { travelAgent: classToPlain(new TravelAgent()) as TravelAgent });
    }

    public goToCreateOrder() {
        if (this.user) {
            this.pageRouter.openCreateOrder(this.user._id);
        }
    }

    @action
    public revealPaymentRequestCreation(): void {
        this.isPaymentRequestFormVisible = true;
    }

    @action
    public hidePaymentRequestCreation(): void {
        this.isPaymentRequestFormVisible = false;
    }

    @observable
    public travelAgentOwner?: SimpleUser;

    @action
    public setTravelAgentOwner(value?: string): void {
        if (this.editedUser && this.editedUser.travelAgent) {
            this.editedUser.travelAgent.ownerId = value;
        }
    }

    @action
    public setPartnerBookingAvailableForAgent(value?: boolean): void {
        if (this.editedUser && this.editedUser.travelAgent) {
            this.editedUser.travelAgent.partnerBookingAvailable = value;
        }
    }

    @action
    public async onTravelAgentOwnerInputChange(value?: string): Promise<void> {
        if (value && value.length > 2) {
            if (!this.users) {
                this.users = [];
            }

            this.users = await this.rpcClient.user.retrieveSimpleUsers({
                searchString: value,
                isDriver: false,
                isCompanyDriver: false,
                isDriversCompany: false,
            });
        }
    }

    public isDataFetched(): this is TravelAgentPageStore & TravelAgentPageStoreDataFetched {
        return !!(this.user || this.editedUser);
    }

    @observable
    public isPRPaymentProviderVisible: boolean = false;

    @action
    public setPRPaymentProviderVisible() {
        this.isPRPaymentProviderVisible = !this.isPRPaymentProviderVisible;
    }

    @computed
    public get travelAgentTier(): string | undefined {
        return !isUndefinedOrNull(this.user?.travelAgent?.realizedOrders)
            ? transformTravelAgentRealizedOrdersToTier(this.user?.travelAgent?.realizedOrders)
            : undefined;
    }

    @observable
    public changeLogs?: TravelAgentChangeLog;

    @observable
    public changedByUsers?: Array<SimpleUser>;

    @observable
    public noChangesFound?: boolean;

    @action
    public async loadChangeLogs(): Promise<void> {
        if (!this.user) {
            this.noChangesFound = true;
            return;
        }

        this.changeLogs = plainToClass(
            TravelAgentChangeLog,
            await this.rpcClient.travelAgent.getTravelAgentChangeLogs(this.user._id),
        );

        if (this.changeLogs !== undefined) {
            await this.loadChangedByUsers(
                this.changeLogs.logs.filter((l) => l.changedByUserId).map((l) => l.changedByUserId) as Array<string>,
            );
        } else {
            this.noChangesFound = true;
        }
    }

    @action
    public async loadChangedByUsers(userIds: Array<string>): Promise<void> {
        this.changedByUsers = await this.rpcClient.user.retrieveSimpleUsers({ userIds });
    }
}

export interface TravelAgentPageStoreDataFetched {
    user: User;
}
