/* eslint-disable max-len */
/* eslint-disable no-alert */
/* eslint-disable no-restricted-syntax */
import { SECOND_IN_MILLISECONDS } from "@daytrip/constants";
import { API_SIGN_IN_TO_CI_AS_CUSTOMER_URL } from "@daytrip/legacy-config";
import type { VehicleTypeCoefficient } from "@daytrip/legacy-models";
import type { Location } from "@daytrip/legacy-models";
import { Order, OrderPriceCalculationValidationOptions } from "@daytrip/legacy-models";
import { transformOrderIdToBookingReference } from "@daytrip/legacy-transformers";
import { getPoolDepartureTimeDetails } from "@daytrip/legacy-utils";
import { transformBookingToUTC } from "@daytrip/utils";
import type { OrderDataForOrderPage } from "@legacy/dataTransferObjects/OrderDataForOrderPage";
import { AssignationStatus } from "@legacy/domain/AssignationStatus";
import { OrderType } from "@legacy/domain/OrderType";
import { PassengerType } from "@legacy/domain/PassengerType";
import { PriceCalculator } from "@legacy/domain/PriceCalculator";
import type { SimpleCountry } from "@legacy/domain/SimpleCountry";
import type { VehicleType } from "@legacy/domain/VehicleType";
import { VehicleTypes } from "@legacy/domain/VehicleType";
import { Assignation } from "@legacy/models/Assignation";
import { CustomerFeedback } from "@legacy/models/CustomerFeedback";
import { validateWithoutLogging } from "@legacy/services/ValidationService";
import autobind from "autobind-decorator";
import { plainToClass } from "class-transformer";
import { action, computed, observable } from "mobx";
import type { LocationDescriptor } from "mobx-react-router";

import { globalManagementLogger } from "../../global-logger";
import { CustomerFeedbackOperator } from "../../operators/CustomerFeedbackOperator";
import { OrderOperator } from "../../operators/OrderOperator";
import { PageStore } from "../../stores/PageStore";
import { FetchDataStatus } from "../../utils/FetchDataStatus";
import { validatePassengersPhoneNumbers } from "../../utils/validatePassengersPhoneNumbers";

import type { OrderPageRouter } from "./OrderPageRouter";
import { checkLocationsDifference } from "./utils/checkLocationsDifference";
import { checkAndConfirmVehicleTypePriceFeeDifference } from "./utils/checkVehicleTypePriceFeeDifference";

const INVALID_ASSIGNATION_STATUSES = [AssignationStatus.Cancelled, AssignationStatus.Declined];

@autobind
export class OrderPageStore extends PageStore<OrderPageRouter, {}> {
    @observable
    public orderOperator: OrderOperator;

    @action
    public async onFetchData() {
        await this.fetchOrder();
    }

    @action
    private async fetchOrder() {
        const orderOperatorModules = { ...this.modules, routingStore: this.pageRouter.routerStore };

        this.orderOperator = new OrderOperator({
            modelConstructor: Order,
            modules: orderOperatorModules,
            beforeEdit: async () => {
                if (this.orderOperator.editedModel === undefined) {
                    const { originLocation, destinationLocation } = this.orderOperator;
                    if (this.orderOperator.originLocationSearchOption == null && originLocation != null) {
                        this.orderOperator.originLocationSearchOption = {
                            value: originLocation._id,
                            label: originLocation.name,
                        };
                    }

                    if (this.orderOperator.destinationLocationSearchOption == null && destinationLocation != null) {
                        this.orderOperator.destinationLocationSearchOption = {
                            value: destinationLocation._id,
                            label: destinationLocation.name,
                        };
                    }

                    this.orderOperator.contentLocationOperators = this.orderOperator.contentLocationOperators.map(
                        (clo) => {
                            clo.data.countries = this.orderOperator.data.simpleCountries as Array<SimpleCountry>;
                            clo.data.location = this.orderOperator.data.locations.find(
                                (l) => l._id === clo.m.locationId,
                            ) as Location;

                            return clo;
                        },
                    );

                    this.orderOperator.customLocationOperators = this.orderOperator.customLocationOperators.map(
                        (clo) => {
                            clo.data.countries = this.orderOperator.data.simpleCountries as Array<SimpleCountry>;

                            return clo;
                        },
                    );

                    this.orderOperator.setFullyFetched();
                }
            },
            data: {
                locations: [],
                assignations: [],
                subsidies: [],
                compensations: [],
                penalties: [],
                discounts: [],
            },
            onFetchData: async (operator: OrderOperator) => {
                await Promise.all([
                    this.loadOrderWithDependentData(operator),
                    this.loadSimpleCountries(operator),
                    this.loadRouterUser(operator),
                    this.loadRegions(operator),
                ]);

                return FetchDataStatus.Success;
            },
            beforeSave: async () => {
                const { orderOperator } = this;

                if (orderOperator.newOrder === false) {
                    const originalOrder = await this.rpcClient.order.retrieveOrder(orderOperator.m._id);

                    await checkLocationsDifference(
                        orderOperator,
                        originalOrder,
                        this.rpcClient.content.retrieveSimpleLocations,
                    );
                    checkAndConfirmVehicleTypePriceFeeDifference(orderOperator);
                }

                await this.orderOperator.validate();
            },
            onSave: async (model: Order) => {
                const { orderOperator } = this;

                let orderWasNew = false;

                const originLocation = await this.rpcClient.content.retrieveLocation(model.originLocationId);
                const departureAtUtc = transformBookingToUTC(model.departureAt, originLocation.timezone);

                const now = new Date();
                if (departureAtUtc < now) {
                    alert(
                        [
                            "Please consider that order departure date is in the past.",
                            `UTC: ${departureAtUtc.toJSON()}`,
                            `Order is in timezone: ${originLocation.timezone} (${model.departureAt.toJSON()})`,
                        ].join("\n"),
                    );
                }

                const phoneNumberValidationResult = validatePassengersPhoneNumbers(orderOperator);

                if (phoneNumberValidationResult) {
                    alert(phoneNumberValidationResult.alertMessage);
                    // if order has API partner (API partner do not support strict phone validation),
                    // allow to save with invalid phone number so CS team can edit such orders
                    if (phoneNumberValidationResult.shouldNotSaveOrderWithInvalidPhones) {
                        return;
                    }
                }

                if (orderOperator && orderOperator.newOrder === false) {
                    const editedOrder = plainToClass(Order, model);

                    if (editedOrder) {
                        try {
                            await this.rpcClient.order.updateOrder(editedOrder._id, editedOrder);

                            this.orderOperator.edit((order) => {
                                if (order.version != null) {
                                    order.version += 1;
                                } else {
                                    order.version = 0;
                                }
                            });
                            this.orderOperator.orderLastSaved = new Date();

                            await validateWithoutLogging(Order, editedOrder, OrderPriceCalculationValidationOptions);

                            // Update assignation price if order prices have changed
                            const currentTotalPrice = PriceCalculator.calculateOrderPrice(
                                editedOrder,
                                orderOperator.orderDiscounts,
                            );
                            if (orderOperator.model.price !== currentTotalPrice) {
                                const assignations = await this.rpcClient.order.retrieveOrderAssignations(
                                    orderOperator.model._id,
                                );
                                const activeAssignations = assignations.filter(
                                    (assignation) => !INVALID_ASSIGNATION_STATUSES.includes(assignation.status),
                                );
                                activeAssignations.forEach(async (assignation) => {
                                    const vehicleOrder = { ...editedOrder };
                                    vehicleOrder.vehicles = [assignation.vehicleType];
                                    await validateWithoutLogging(
                                        Order,
                                        vehicleOrder as Order,
                                        OrderPriceCalculationValidationOptions,
                                    );
                                    assignation.price = PriceCalculator.calculateOrderPrice(
                                        vehicleOrder as Order,
                                        orderOperator.orderDiscounts,
                                    );
                                    assignation.fee = PriceCalculator.calculateOrderFee(
                                        vehicleOrder as Order,
                                        orderOperator.orderDiscounts,
                                    );
                                    await this.rpcClient.assignation.updateAssignation(assignation._id, assignation);
                                });
                            }
                        } catch (e: any) {
                            alert(
                                `Unable to update order. It could be the result of someone updated this order meanwhile. Please, reload the page and try again.\n\n${e}`,
                            );
                            globalManagementLogger.error(e);
                        }
                    }
                } else if (this.orderOperator.newOrder === true) {
                    orderWasNew = true;
                    // return coefficients = 1 if no blacklisted date price coefficient
                    const priceCoefficient = await this.retrieveBlacklistedDatePriceCoefficient(model);

                    model.vehicleTypesPricesFees.forEach((vtpf, i) => {
                        const vehicleCoefficient = priceCoefficient.vehicleCoefficients.find(
                            (coefficient) => coefficient.vehicleType === vtpf.vehicleType,
                        );
                        if (vehicleCoefficient) {
                            model.vehicleTypesPricesFees[i].price = Math.round(
                                model.vehicleTypesPricesFees[i].price / vehicleCoefficient.priceAdjustmentCoefficient,
                            );
                            model.vehicleTypesPricesFees[i].fee = Math.round(
                                model.vehicleTypesPricesFees[i].fee / vehicleCoefficient.feeAdjustmentCoefficient,
                            );
                        }
                    });
                    model.travelAgentId = orderOperator.travelAgentId;
                    const savedOrderId = await this.rpcClient.order.createOrder(model);

                    orderOperator.orderLastSaved = new Date();
                    orderOperator.newOrder = false;
                    orderOperator.m._id = savedOrderId;
                }

                await orderOperator.retrieveLatestPendingChange();

                if (orderOperator.vehicleOperators) {
                    for (const vehicleOperator of orderOperator.vehicleOperators) {
                        const { assignationOperator } = vehicleOperator;

                        if (assignationOperator?.model !== undefined && assignationOperator.isSaved !== true) {
                            assignationOperator.edit((a) => {
                                a.orderId = orderOperator.m._id;
                            });
                            await assignationOperator.save();
                            orderOperator.data.assignations.push(assignationOperator.model);
                            assignationOperator.isSaved = true;

                            if (assignationOperator.customerFeedbackOperator !== undefined) {
                                assignationOperator.customerFeedbackOperator.edit();
                                await assignationOperator.customerFeedbackOperator.save();
                            }
                        }
                    }
                }

                if (orderOperator.paymentRequestOperators.length > 0) {
                    orderOperator.paymentRequestOperators.forEach(async (pro) => {
                        pro.edit((pr) => {
                            pr.orderId = orderOperator.m._id;
                        });
                        await pro.save();
                    });
                }

                if (orderOperator.paymentOperators.length > 0) {
                    orderOperator.paymentOperators.forEach(async (po) => {
                        po.edit((p) => {
                            p.orderId = orderOperator.m._id;
                        });
                        await po.save();
                    });
                }

                if (orderWasNew) {
                    this.orderOperator.modules.routingStore.push({
                        pathname: "order",
                        query: { orderId: orderOperator.m._id },
                    } as LocationDescriptor);
                }
            },
            afterSave: async () => {
                // if there is error in passengers phone numbers,
                // do not fetch data and
                // keep updated values in edit mode because save was not performed
                const phoneNumberValidationResult = validatePassengersPhoneNumbers(this.orderOperator);
                if (
                    phoneNumberValidationResult?.shouldNotSaveOrderWithInvalidPhones &&
                    phoneNumberValidationResult?.alertMessage
                ) {
                    this.orderOperator.edit(() => {});
                    return;
                }
                await this.fetchData();
            },
            validateOptions: { skipMissingProperties: true },
        });

        await this.orderOperator.fetchData();

        if (this.orderOperator.newOrder) {
            this.orderOperator.addPassenger(PassengerType.Lead);

            let { userId } = this.pageRouter;
            if (!userId) {
                userId = this.authenticationStore.userJWT?.userId;
            }

            if (!userId) {
                throw new Error("User id is not defined");
            }

            if (!this.orderOperator.data.simpleUsers?.length) {
                this.orderOperator.data.simpleUsers = await this.rpcClient.user.retrieveSimpleUsers({
                    userIds: [userId],
                });
            }

            this.orderOperator.edit((o) => (o.userId = userId!));
        }
    }

    private async retrieveBlacklistedDatePriceCoefficient(model: Order): Promise<{
        priceAdjustmentCoefficient: number;
        feeAdjustmentCoefficient: number;
        vehicleCoefficients: VehicleTypeCoefficient[];
    }> {
        const priceCoefficient = await this.rpcClient.order.getOrderPriceAdjustmentCoefficient(
            model.originLocationId,
            model.destinationLocationId,
            model.departureAt,
            model.vehicles,
            model.pricingCountryId,
            model.routeId,
            model.dynamicPricing?.distanceKm,
            model.apiPartnerId,
            model.dynamicPricing?.vehiclesWithOnlyOriginPricing,
            model.dynamicPricing?.vehiclesWithOnlyDestinationPricing,
            model.pickupPosition,
            model.dropoffPosition,
        );

        if (
            priceCoefficient.vehicleCoefficients.length &&
            priceCoefficient.vehicleCoefficients.some(
                (coefficient) =>
                    coefficient.priceAdjustmentCoefficient !== 1 || coefficient.feeAdjustmentCoefficient !== 1,
            )
        ) {
            const confirmedBlacklistedDateCoefficient = confirm(
                `This date has average price coefficient of ${priceCoefficient.priceAdjustmentCoefficient} and average fee coefficient of ${priceCoefficient.feeAdjustmentCoefficient}, would you like to apply it to order total? Clicking OK will recalculate the price you have entered into the booking
                \nIf you click cancel, order will be created without the coefficient.`,
            );
            if (!confirmedBlacklistedDateCoefficient) {
                return {
                    priceAdjustmentCoefficient: priceCoefficient.priceAdjustmentCoefficient ?? 1,
                    feeAdjustmentCoefficient: priceCoefficient.feeAdjustmentCoefficient ?? 1,
                    vehicleCoefficients: priceCoefficient.vehicleCoefficients,
                };
            }
        }
        return { priceAdjustmentCoefficient: 1, feeAdjustmentCoefficient: 1, vehicleCoefficients: [] };
    }

    @computed
    public get poolOrderTimeRange(): string | undefined {
        const { meetingPosition, ride } = this.orderOperator.data;
        if (this.orderOperator.m.type === OrderType.Private || ride === undefined) {
            return;
        }

        const { formattedDepartureTimeText } = getPoolDepartureTimeDetails(
            {
                departureTimeEarliest: new Date(ride.departureAt).getTime() / SECOND_IN_MILLISECONDS,
                departureTimeLatest: new Date(ride.departureAtLatest).getTime() / SECOND_IN_MILLISECONDS,
                originTimezone: "UTC",
            },
            meetingPosition,
        );

        return `Pool: Departure time range for ${
            meetingPosition ? "this" : "custom"
        } pick-up position is set ${formattedDepartureTimeText}`;
    }

    public loginAsCustomer(): void {
        const email = this.orderOperator.user?.email ?? "";
        window.open(
            `${API_SIGN_IN_TO_CI_AS_CUSTOMER_URL}?email=${email}&bookingRef=${transformOrderIdToBookingReference(
                this.orderOperator.m._id,
            )}`,
            "_blank",
        );
    }

    @computed
    public get fetchHasFailed(): boolean {
        return this.orderOperator.fetchDataStatus === FetchDataStatus.Error;
    }

    public isDataFetched(): this is OrderPageStore & OrderPageStoreDataFetched {
        return this.orderOperator !== undefined;
    }

    @action
    public async cancelEdit() {
        this.orderOperator.cancelEdit();

        this.orderOperator.populatePassengersOperators();
        this.orderOperator.dynamicPriceDifference = 0;
        await this.orderOperator.retrieveVehiclesData();
        const order = await this.rpcClient.order.retrieveOrder(this.orderOperator.m._id);
        this.orderOperator.updateUiContentLocations(order);
    }

    private async loadOrderWithDependentData(operator: OrderOperator): Promise<void> {
        const order = await this.fetchOrCreateOrder(operator);

        // Operations that need order to be fetched
        await Promise.all([
            operator.retrieveVehiclesData(),
            operator.retrieveOrderPayments(),
            this.fetchRouteData(order, operator),
            operator.retrieveLatestPendingChange(),
            operator.fetchMeetingPositions(),
            this.loadPartner(order, operator),
            this.loadApiPartner(order, operator),
            this.loadUserFromOrder(order, operator),
        ]);

        operator.populatePassengersOperators();

        if (order.customMarkup) {
            operator.affiliateCustomMarkup = order.customMarkup;
        }
    }

    private async fetchOrCreateOrder(operator: OrderOperator) {
        const { orderId } = this.pageRouter;

        let order: undefined | Order;
        let orderData: undefined | OrderDataForOrderPage;

        if (orderId) {
            orderData = await this.rpcClient.order.retrieveOrderDataForOrderPage(orderId);
            order = plainToClass(Order, orderData.order);
        }

        if (order !== undefined && orderData !== undefined) {
            operator.data = {
                ...operator.data,
                ...orderData,
                simpleUsers: [orderData.simpleCustomer],
                assignations: plainToClass(Assignation, orderData.assignations),
                locations: [...orderData.locations, orderData.originLocation, orderData.destinationLocation],
            };

            const passengersSortingMappings = {
                [PassengerType.Lead]: 1,
                [PassengerType.Adult]: 2,
                [PassengerType.Child]: 3,
            };

            order.passengers = order.passengers.sort(
                (a, b) => passengersSortingMappings[a.type] - passengersSortingMappings[b.type],
            );
        }

        if (order == null) {
            order = new Order();
            order.vehicleTypesPricesFees = VehicleTypes.map((vehicleType: VehicleType) => ({
                vehicleType,
                price: 0,
                fee: 0,
                isManuallyModified: false,
            }));
            operator.newOrder = true;
        } else {
            operator.originalVehicleTypesPricesFees = order.vehicleTypesPricesFees;
        }

        if (orderData) {
            operator.customerFeedbacks = orderData.customerFeedbacks.map((cf) => {
                const cfo = new CustomerFeedbackOperator({
                    modelConstructor: CustomerFeedback,
                    model: cf,
                    modules: null,
                    data: null,
                    onSave: async (model) => {
                        await this.rpcClient.feedback.updateCustomerFeedback(cf._id, model);
                    },
                });

                return cfo;
            });

            operator.orderDiscounts = orderData.discounts;
        }

        operator.model = order;

        operator.currency = order.currency;
        operator.currencyRate = order.currencyRate;

        return order;
    }

    private async fetchRouteData(order: Order, operator: OrderOperator) {
        if (!operator.newOrder) {
            operator.setOrderRoute({ route: operator.data.route, isInitialFetch: true });
            await operator.calculateLocationsTravelData(order.contentLocations, order.customLocations);
            operator.populateContentLocationOperators();
            operator.populateCustomLocationOperators();
            await operator.setInitialLocationArrivals();
        }
    }

    private async loadPartner(order: Order, operator: OrderOperator) {
        if (order.partnerId) {
            operator.partner = await this.rpcClient.partner.retrievePartnerById(order.partnerId);
        }
    }

    private async loadApiPartner(order: Order, operator: OrderOperator) {
        if (order.apiPartnerId) {
            try {
                const apiPartner = await this.rpcClient.apiPartner.getApiPartnerById(order.apiPartnerId);
                operator.apiPartner = apiPartner;
            } catch (e) {
                // do nothing
            }
        }
    }

    private async loadRouterUser(operator: OrderOperator) {
        if (this.pageRouter.userId != null) {
            const [user] = await this.rpcClient.user.retrieveSimpleUsers({ userIds: [this.pageRouter.userId] });
            if (operator.data.simpleUsers) {
                operator.data.simpleUsers.push(user);
            }
        }
    }

    private async loadSimpleCountries(operator: OrderOperator) {
        const countries = await this.rpcClient.content.retrieveSimpleCountries({});
        operator.data.simpleCountries = countries;
    }

    private async loadRegions(operator: OrderOperator) {
        operator.data.regions = await this.rpcClient.content.retrieveRegions({});
    }

    private async loadUserFromOrder(order: Order, operator: OrderOperator) {
        if (!order.userId || this.authenticationStore.isRegionalManager) {
            return;
        }
        const userFromOrder = await this.rpcClient.user.retrieveUser(order.userId, true);
        operator.data.userFromOrder = userFromOrder;
    }
}

export interface OrderPageStoreDataFetched {
    orderOperator: OrderOperator;
}
