import { LocationImage } from "@daytrip/legacy-models";
import { TransformToDate } from "@daytrip/legacy-transformers";
import { Expose, Type } from "class-transformer";
import { ArrayNotEmpty, IsNumber, IsArray, IsDefined, IsUUID, IsDate, IsString } from "class-validator";
import { v4 as uuid } from "uuid";

import { CDEntity, CDObjectIdColumn, CDColumn, CDBaseEntity } from "../orm";


import { SellingPoint } from "./SellingPoint";

@CDEntity("poolLocations")
export class PoolLocation extends CDBaseEntity {
    @CDObjectIdColumn()
    @IsUUID("4")
    @Expose()
    public _id: string = uuid();

    @CDColumn()
    @Expose()
    @IsUUID("4")
    @IsDefined()
    public locationId: string;

    @CDColumn()
    @IsNumber()
    @Expose()
    @IsDefined()
    public radiusKm: number;

    @CDColumn()
    @IsArray()
    @IsDefined()
    @Expose()
    @Type(() => SellingPoint)
    public sellingPoints: SellingPoint[] = [];

    @CDColumn()
    @ArrayNotEmpty()
    @IsArray()
    @IsDefined()
    @Expose()
    @Type(() => LocationImage)
    public images: LocationImage[] = [];

    @CDColumn()
    @IsDate()
    @IsDefined()
    @Expose()
    @IsDefined()
    @TransformToDate
    public createdAt: Date = new Date();

    @CDColumn()
    @IsUUID("4")
    @Expose()
    @IsDefined()
    public createdBy: string;

    @CDColumn()
    @IsString()
    @Expose()
    @IsDefined()
    public perex: string;
}
