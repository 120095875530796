import { OrderDataForOrdersPage } from "@legacy/dataTransferObjects/OrderDataForOrdersPage";
import { PartnerType } from "@legacy/domain/PartnerType";
import type { SimpleCountry } from "@legacy/domain/SimpleCountry";
import { Partner } from "@legacy/models/Partner";
import { RetrieveOrdersOptions } from "@legacy/options/RetrieveOrdersOptions";
import autobind from "autobind-decorator";
import { classToPlain } from "class-transformer";
import { action, observable } from "mobx";

import { globalManagementLogger } from "../../global-logger";
import { PageStore } from "../../stores/PageStore";
import { PaginatedDataStore } from "../../stores/PaginatedDataStore";

import type { PartnerRouter } from "./PartnerRouter";

@autobind
export class PartnerStore extends PageStore<PartnerRouter, null> {
    public onInit() {
        const { partnerId } = this.pageRouter;
        this.isNewPartner = !partnerId;

        if (partnerId) {
            this.orders = new PaginatedDataStore(
                OrderDataForOrdersPage,
                RetrieveOrdersOptions,
                this.rpcClient.order.retrieveOrdersDataForOrdersPage,
                (options) => this.rpcClient.order.retrieveOrdersCount(options),
                this.pageRouter,
                "partnerOrders_",
                {
                    skip: 1,
                    limit: 30,
                    partnerId,
                    sortBy: "departureAt",
                    sortDirection: -1,
                },
            );
        }
    }

    @observable
    public partner!: Partner;

    @observable
    public isNewPartner: boolean = false;

    @observable
    public countries!: Array<SimpleCountry>;

    @observable
    public orders!: PaginatedDataStore<RetrieveOrdersOptions, OrderDataForOrdersPage>;

    @observable
    public isEditMode: boolean = false;

    @action
    public setEditMode(isEditMode: boolean) {
        this.isEditMode = isEditMode;
    }

    @action
    public updateName(name: string) {
        this.partner.name = name;
    }

    @action
    public updateAlias(alias: string) {
        this.partner.alias = alias;
    }

    @action
    public updateDescription(description: string) {
        this.partner.description = description;
    }

    @action
    public updateType(newType: PartnerType) {
        this.partner.type = newType;
    }

    @action
    public updateLocationId(locationId: string) {
        this.partner.locationId = locationId;
    }

    @action
    public updateExpirationDays(days: string) {
        this.partner.expirationDays = Number(days);
    }

    @action
    public async save() {
        const id = await this.rpcClient.partner.upsertPartner(this.partner);
        this.setEditMode(false);

        if (this.pageRouter.partnerId !== id) {
            this.pageRouter.openPartnerWithId(id);
        }
    }

    @action
    public async delete() {
        try {
            await this.rpcClient.partner.deletePartner(this.partner._id);
            window.history.back();
        } catch (error: any) {
            alert(`Oh, something is wrong. :(\nError:\n${error.message}`);
            globalManagementLogger.error(error);
        }
    }

    @action
    public cancel() {
        if (this.pageRouter.partnerId) {
            this.setEditMode(false);
        } else {
            window.history.back();
        }
    }

    @action
    public async onFetchData() {
        await this.fetchContent();
    }

    @action
    public async fetchContent(): Promise<void> {
        this.countries = await this.rpcClient.content.retrieveSimpleCountries({});

        if (!this.pageRouter.partnerId) {
            this.isEditMode = true;
        }

        let partner: Partner | undefined;

        if (this.pageRouter.partnerId) {
            partner = await this.rpcClient.partner.retrievePartnerById(this.pageRouter.partnerId);
        } else {
            partner = classToPlain(Partner.referral()) as Partner;
        }

        if (!partner) {
            this.pageRouter.openPartnerWithId();
        } else {
            this.partner = partner;
        }

        if (partner && this.orders?.options) {
            this.orders.options.partnerId = partner?._id;
            this.orders.fetch();
        }
    }

    public isDataFetched(): this is PartnerStore & PartnerStoreDataFetched {
        if (this.isNewPartner) {
            return Boolean(this.partner && this.countries);
        }

        return Boolean(this.partner && this.countries && this.orders);
    }
}

export interface PartnerStoreDataFetched {
    partner: Partner;
}
