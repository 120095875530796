import { HOUR_IN_MILLISECONDS } from "@daytrip/constants";
import { PENDING_ASSIGNATION_TIME_LIMIT_IN_HOURS } from "@daytrip/legacy-config";
import type { OrderContentLocation } from "@daytrip/legacy-models";
import { MeetingPosition } from "@daytrip/legacy-models";
import { Ride } from "@daytrip/legacy-models";
import { Location } from "@daytrip/legacy-models";
import { VehicleTypePriceFee } from "@daytrip/legacy-models";
import { calculateDepartInFromBookingTime } from "@daytrip/legacy-utils";
import { transformBookingToUTC } from "@daytrip/utils";
import { AnonymizedOrderForDriver } from "@legacy/domain/AnonymizedOrderForDriver";
import type { AnonymizedPassenger } from "@legacy/domain/AnonymizedPassenger";
import { AssignationWithAnonymizedOrder } from "@legacy/domain/AssignationWithAnonymizedOrder";
import { OrderStatus } from "@legacy/domain/OrderStatus";
import { PaymentMethod } from "@legacy/domain/PaymentMethod";
import type { RentalVehicle } from "@legacy/domain/RentalVehicle";
import { VehicleInfo } from "@legacy/domain/VehicleInfo";
import { filterSuitableVehicleInfo } from "@legacy/filters/filterSuitableVehicle";
import type { VehicleMake } from "@legacy/models/VehicleMake";
import type { VehicleModel } from "@legacy/models/VehicleModel";
import { getRequiredCapacityForVehicle } from "@legacy/utils/getRequiredCapacityForVehicle";
import { isBookingDepartureInThePast } from "@legacy/utils/isBookingDepartureInThePast";
import autobind from "autobind-decorator";
import { plainToClass } from "class-transformer";
import { differenceInHours } from "date-fns";
import { action, computed, observable } from "mobx";

import { getRpcClient } from "../rpc-browser-sdk";

export enum AssignationStatus {
    Pending,
    Accepted,
    Declined,
    Cancelled,
}

export enum ResponseStatus {
    None,
    Accept,
    Decline,
    Change,
}

@autobind
export class AssignationWithOrderOperator {
    private rpcClient = getRpcClient();

    @observable
    public assignation: AssignationWithAnonymizedOrder;

    @observable
    public order: AnonymizedOrderForDriver;

    @computed
    public get acceptableUntil(): Date {
        return new Date(
            this.assignation.createdAt.getTime() + PENDING_ASSIGNATION_TIME_LIMIT_IN_HOURS * HOUR_IN_MILLISECONDS,
        );
    }

    @computed
    public get isDeclinable(): boolean {
        return new Date().getTime() < this.acceptableUntil.getTime();
    }

    @computed
    public get adultPassengers(): Array<AnonymizedPassenger> {
        return this.order.passengers.filter((p) => !p.isChild() && p.assignationId === this.assignation._id);
    }

    @computed
    public get childrenPassengers(): Array<AnonymizedPassenger> {
        return this.order.passengers.filter((p) => p.isChild() && p.assignationId === this.assignation._id);
    }

    // real count of passengers/luggage count for this vehicle (not for whole order)
    private requiredVehicleCapacity: number;

    @observable
    public origin: Location;

    @observable
    public destination: Location;

    @observable
    public locations: Array<Location>;

    @observable
    public responseStatus: ResponseStatus = ResponseStatus.None;

    @observable
    public vehicles: Array<VehicleInfo>;

    @observable
    public vehicleMakes: Array<VehicleMake>;

    @observable
    public vehicleModels: Array<VehicleModel>;

    @observable
    public vehicleMakeIdToRent?: string;

    @observable
    public vehicleModelIdToRent?: string;

    @observable
    public acceptationNote?: string;

    @observable
    public vehicleId: string;

    @observable
    public driverId?: string;

    @observable
    public leadPassengerName?: string;

    @observable
    public leadPassengerPhoneNumber?: string;

    @observable
    public bookingReference?: string;

    @observable
    public isLeadPassengerPhoneNumberLoading: boolean = false;

    @observable
    public userId: string;

    @observable
    public ride?: Ride;

    @action
    public async showLeadPhoneNumber() {
        this.isLeadPassengerPhoneNumberLoading = true;
        try {
            this.leadPassengerPhoneNumber =
                (await this.rpcClient.assignation.getCustomerPhoneNumber(this.assignation._id, this.userId)) ||
                "No phone number";
        } catch (error: any) {
            console.error({
                message: "Unable to show lead passenger phone number",
                error,
            });
        }
        this.isLeadPassengerPhoneNumberLoading = false;
    }

    @computed
    public get isLessThan12HoursUntilDeparture(): boolean {
        return calculateDepartInFromBookingTime(this.order.departureAt, this.origin.timezone, "hours") < 12;
    }

    @computed
    public get canDriverRevealPhoneNumber(): boolean {
        if (this.order.status !== OrderStatus.Confirmed) {
            return false;
        }
        return (
            calculateDepartInFromBookingTime(this.order.departureAt, this.origin.timezone, "hours") < 20 &&
            calculateDepartInFromBookingTime(this.order.departureAt, this.origin.timezone, "hours") > -1
        );
    }

    @computed
    public get isBookingDepartureInThePast(): boolean {
        return isBookingDepartureInThePast(this.order.departureAt, this.origin.timezone);
    }

    @computed
    public get calculateDepartInFromBookingTime(): number {
        return calculateDepartInFromBookingTime(this.order.departureAt, this.origin.timezone);
    }

    @computed
    public get departureAtUtc(): Date {
        return transformBookingToUTC(this.order.departureAt, this.origin.timezone);
    }

    @computed
    public get untilDepartureHours(): number {
        return differenceInHours(this.departureAtUtc, new Date());
    }

    @computed
    public get status(): AssignationStatus {
        if (!this.assignation) {
            return AssignationStatus.Pending;
        }

        if (this.assignation.cancelledAt) {
            return AssignationStatus.Cancelled;
        }

        if (this.assignation.declinedAt) {
            return AssignationStatus.Declined;
        }

        if (this.assignation.acceptedAt) {
            return AssignationStatus.Accepted;
        }

        return AssignationStatus.Pending;
    }

    @computed
    public get tripPriceFee(): VehicleTypePriceFee {
        const orderPrice = this.order.vehicleTypesPricesFees.find(
            (price) => price.vehicleType == this.assignation.vehicleType,
        ) as VehicleTypePriceFee;

        this.order.contentLocations.forEach((cl) => {
            const vehicleTypeIndex = cl.vehicleTypesPricesFees.findIndex(
                (v) => v.vehicleType == this.assignation.vehicleType,
            );

            orderPrice.price += cl.vehicleTypesPricesFees[vehicleTypeIndex].price;
            orderPrice.fee += cl.vehicleTypesPricesFees[vehicleTypeIndex].fee;
        });

        this.order.customLocations.forEach((cl) => {
            const vehicleTypeIndex = cl.vehicleTypesPricesFees.findIndex(
                (v) => v.vehicleType == this.assignation.vehicleType,
            );

            orderPrice.price += cl.vehicleTypesPricesFees[vehicleTypeIndex].price;
            orderPrice.fee += cl.vehicleTypesPricesFees[vehicleTypeIndex].fee;
        });

        return orderPrice;
    }

    @computed
    public get orderLocationsWithTitleAndDurations(): Array<{ title: string; localNames?: string }> {
        let result = this.locations.map((l) => {
            const { duration } = this.order.contentLocations.find(
                (cl) => cl.locationId == l._id,
            ) as OrderContentLocation;

            return {
                title: `${l.name}\xa0(${duration}\xa0m.)`,
                localNames: l.localNames ? l.localNames.join(", ") : undefined,
            };
        });

        if (this.order.customLocations != undefined && this.order.customLocations.length > 0) {
            result = result.concat(
                this.order.customLocations.map((cl) => ({
                    title: `${cl.title}\xa0(${cl.duration}\xa0m.)`,
                    localNames: undefined,
                })),
            );
        }

        return result;
    }

    @computed
    public get orderLocationsWithDurations(): Array<string> {
        let result = this.locations.map((l) => {
            const { duration } = this.order.contentLocations.find(
                (cl) => cl.locationId == l._id,
            ) as OrderContentLocation;
            return `${l.name}\xa0(${duration}\xa0m.)`;
        });

        if (this.order.customLocations != undefined && this.order.customLocations.length > 0) {
            result = result.concat(this.order.customLocations.map((cl) => `${cl.title}\xa0(${cl.duration}\xa0m.)`));
        }

        return result;
    }

    @action
    public setResponseStatus(responseStatus: ResponseStatus) {
        if (responseStatus == ResponseStatus.Change) {
            this.driverId = this.assignation.driverId;
        }

        this.responseStatus = responseStatus;
    }

    @computed
    public get vehicleInfo(): VehicleInfo | undefined {
        return this.vehicles.find((v) => v._id == this.assignation.vehicleId);
    }

    @computed
    public get vehicleTitle(): string | undefined {
        if (this.vehicleInfo != undefined) {
            return this.vehicleInfo.title;
        }
        return undefined;
    }

    @action
    public async accept(cb?: () => void) {
        if (
            (!this.driverId && this.assignation.forCompany) ||
            ((!this.vehicleId || this.vehicleId === "") && (!this.acceptationNote || this.acceptationNote === ""))
        ) {
            alert("Please, make sure that all values are selected.");
            return;
        }

        const vehicleId = this.vehicleId !== "" ? this.vehicleId : undefined;

        const rentalVehicle =
            this.vehicleMakeIdToRent && this.vehicleModelIdToRent
                ? {
                      makeId: this.vehicleMakeIdToRent,
                      modelId: this.vehicleModelIdToRent,
                  }
                : undefined;

        if (this.assignation.rideId) {
            await this.rpcClient.ride.acceptAssignations(
                this.assignation.rideId,
                vehicleId,
                this.acceptationNote,
                this.assignation.forCompany ? this.driverId : undefined,
                rentalVehicle,
            );
        } else {
            await this.rpcClient.assignation.acceptAssignation(
                this.assignation._id,
                vehicleId,
                this.acceptationNote,
                this.assignation.forCompany ? this.driverId : undefined,
                rentalVehicle,
            );
        }

        this.assignation.acceptedAt = new Date();
        this.assignation.vehicleId = vehicleId;
        this.assignation.acceptationNote = this.acceptationNote;
        this.assignation.version += 1;

        this.responseStatus = ResponseStatus.None;

        if (cb) {
            await cb();
        }
    }

    @observable
    public invalidDeclinationReason: boolean = false;

    @action
    public async decline(): Promise<void> {
        if (this.declinationReason.length < 3) {
            this.invalidDeclinationReason = true;
            return;
        }

        this.assignation.declinedAt = new Date();

        if (this.assignation.rideId) {
            await this.rpcClient.ride.declineAssignations(this.assignation.rideId, this.declinationReason);
        } else {
            await this.rpcClient.assignation.declineAssignation(this.assignation._id, this.declinationReason);
        }
        this.responseStatus = ResponseStatus.None;
    }

    @computed
    public get saveAssignationDisabled(): boolean {
        // disabled untill driver and car/note are set in company accept assignation modal
        return !(
            this.driverId &&
            ((this.vehicleId && this.vehicleId !== "") || (this.acceptationNote && this.acceptationNote !== ""))
        );
    }

    @action
    public async save(cb?: () => void) {
        if ((!this.vehicleId || this.vehicleId === "") && (!this.acceptationNote || this.acceptationNote === "")) {
            alert("Please, make sure that all values are selected.");
            return;
        }

        let rentalVehicle: RentalVehicle | undefined;
        if (!this.vehicleId) {
            if (!this.vehicleMakeIdToRent || !this.vehicleModelIdToRent) {
                alert("Either vehicle or rental vehicle must be selected.");
                return;
            }
            rentalVehicle = {
                makeId: this.vehicleMakeIdToRent,
                modelId: this.vehicleModelIdToRent,
            };
        }

        if (this.assignation.rideId) {
            await this.rpcClient.ride.updateAcceptedAssignations(
                this.assignation.rideId,
                this.vehicleId ? this.vehicleId : undefined,
                this.acceptationNote,
                this.driverId,
                rentalVehicle,
            );
        } else {
            await this.rpcClient.assignation.updateAcceptedAssignation(
                this.assignation._id,
                this.vehicleId ? this.vehicleId : undefined,
                this.acceptationNote,
                this.driverId,
                rentalVehicle,
            );
        }
        this.responseStatus = ResponseStatus.None;

        if (cb) {
            await cb();
        }
    }

    @action
    public updateAcceptationNote() {
        const make = this.vehicleMakes.find((vehicleMake) => vehicleMake._id === this.vehicleMakeIdToRent);
        const model = this.vehicleModels.find((vehicleMake) => vehicleMake._id === this.vehicleModelIdToRent);

        if (make && model) {
            this.acceptationNote = `${make.name} ${model.name}`;
        }
    }

    @action
    public async updateVehicle(vehicleId: string) {
        this.vehicleId = vehicleId;

        // if rental car was selected
        if (!vehicleId) {
            const vehicleMakes = await this.rpcClient.vehicle.retrieveVehicleMakes();
            this.vehicleModels = await this.rpcClient.vehicle.retrieveSuitableVehicleModels({
                approvedForCountryIds: [this.origin.countryId, this.destination.countryId],
                type: this.assignation.vehicleType,
                requiredVehicleCapacity: this.requiredVehicleCapacity,
            });

            this.vehicleMakes = vehicleMakes.filter(
                (vm) => this.vehicleModels.map((m) => m.makeId).indexOf(vm._id) > -1,
            );
        } else {
            this.acceptationNote = undefined;
        }
    }

    @action
    public updateDriver(driverId: string) {
        this.driverId = driverId;
    }

    @observable
    public declinationReason = "";

    @action
    public updateReason(value: string) {
        if (this.invalidDeclinationReason) {
            this.invalidDeclinationReason = false;
        }
        this.declinationReason = value;
    }

    @observable
    public priceFetched: boolean = false;

    @observable
    public completeOrderPrice: number = 0;

    @observable
    public completeOrderFee: number = 0;

    @observable
    public meetingPosition?: MeetingPosition;

    public suspiciousCCActivity: boolean = false;

    public ccAlias: string = "##XX";

    public ccHolderName?: string;

    public constructor(
        assignation: AssignationWithAnonymizedOrder,
        origin: Location,
        destination: Location,
        locations: Location[],
        driverId?: string,
        suspiciousCCActivity?: boolean,
        ccAlias?: string,
        ccHolderName?: string,
        ride?: Ride,
    ) {
        this.driverId = driverId;
        this.origin = origin;
        this.destination = destination;
        this.locations = locations;
        this.ride = ride;

        if (suspiciousCCActivity && ccAlias) {
            this.suspiciousCCActivity = suspiciousCCActivity;
            this.ccAlias = ccAlias;
            this.ccHolderName = ccHolderName;
        }

        if (!assignation.acceptationNote) {
            assignation.acceptationNote = "";
        }

        if (assignation.meetingPosition && assignation.meetingPosition.length) {
            this.meetingPosition = plainToClass(MeetingPosition, assignation.meetingPosition[0]);
        }

        if (!assignation.reason) {
            assignation.reason = "";
        }

        const a = { ...assignation };

        this.order = plainToClass(AnonymizedOrderForDriver, a.order);

        this.requiredVehicleCapacity = getRequiredCapacityForVehicle({
            vehicleType: a.vehicleType,
            order: this.order,
            vehiclePassengersCount: this.order.passengers.filter((p) => p.assignationId === assignation._id).length,
        });

        this.vehicles = plainToClass(VehicleInfo, a.userVehicles).filter(
            filterSuitableVehicleInfo(a.vehicleType, this.requiredVehicleCapacity, [
                this.origin.countryId,
                this.destination.countryId,
            ]),
        );

        a.order = undefined;
        a.userVehicles = undefined;

        if (
            this.order.paymentMethod === PaymentMethod.Partner ||
            this.order.paymentMethod === PaymentMethod.StatementOfBooking
        ) {
            a.paidCash = 0;
        }

        this.assignation = plainToClass(AssignationWithAnonymizedOrder, a);
    }
}
