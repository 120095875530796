import autobind from "autobind-decorator";
import { action, observable } from "mobx";

import { Document } from "@legacy/models/Document";
import { isUndefinedOrNull } from "@legacy/utils";

import { ModelOperator } from "./ModelOperator";

@autobind
export class DocumentOperator extends ModelOperator<Document, {}, null, null, null> {
    @observable
    public docuemntDeclinationReason?: string;

    @action
    public onChangeDeclinationReason(value: string) {
        this.docuemntDeclinationReason = value;
    }

    @action
    public async approveDocument() {
        try {
            this.model = await this.rpcClient.driver.approveDocument(this.m._id);
        } catch (e: any) {
            alert("Oh, something got wrong. :(");
        }
    }

    @action
    public async declineDocument() {
        if (isUndefinedOrNull(this.docuemntDeclinationReason) || this.docuemntDeclinationReason == "") {
            alert("Please write some declination reason.");
            return;
        }

        try {
            this.model = await this.rpcClient.driver.declineDocument(this.m._id, this.docuemntDeclinationReason);
            this.docuemntDeclinationReason = undefined;
        } catch (e: any) {
            alert("Oh, something got wrong. :(");
        }
    }
}
