import { PoolAvailabilityConfigDetails } from "@legacy/services/PoolAvailabilityConfigService.type";
import autobind from "autobind-decorator";
import { action, computed, observable, toJS } from "mobx";

import { PageStore } from "../../stores/PageStore";

import { PoolAvailabilityConfigPageRouter } from "./PoolAvailabilityConfigPageRouter";

@autobind
export class PoolAvailabilityConfigPageStore extends PageStore<PoolAvailabilityConfigPageRouter> {
    @computed
    get availabilityConfig(): PoolAvailabilityConfigDetails | undefined {
        return this.editAvailabilityConfig ?? this.keepAvailabilityConfig;
    }

    @observable
    keepAvailabilityConfig: PoolAvailabilityConfigDetails | undefined = undefined;

    @observable
    editAvailabilityConfig: PoolAvailabilityConfigDetails | undefined = undefined;

    @computed
    get isEdit(): boolean {
        return !!this.editAvailabilityConfig;
    }

    @observable
    public editLoading = false;

    @observable
    public editError: any | undefined;

    @observable
    public loading = false;

    @observable
    public error: any | undefined;

    @action
    public async fetchAvailabilityConfig(id: string) {
        this.loading = true;
        let data;
        try {
            data = await this.rpcClient.poolAvailabilityConfig.getPoolAvailabilityConfigDetailsById(id);
        } catch (error) {
            this.error = error;
        }
        this.keepAvailabilityConfig = data;
        this.loading = false;
    }

    @action
    public startEdit() {
        this.editAvailabilityConfig = toJS(this.keepAvailabilityConfig);
    }

    @action
    public onChange<PoolAvailabilityConfigKey extends keyof PoolAvailabilityConfigDetails>(
        key: PoolAvailabilityConfigKey,
        value: PoolAvailabilityConfigDetails[PoolAvailabilityConfigKey],
    ) {
        this.editAvailabilityConfig[key] = value;
    }

    @action
    public async onConfirm() {
        this.editLoading = true;
        let data;
        try {
            await this.rpcClient.poolAvailabilityConfig.editPoolAvailabilityConfig(this.availabilityConfig.id, {
                totalVehicles: this.editAvailabilityConfig.totalVehicles,
                seatsPerVehicle: this.editAvailabilityConfig.seatsPerVehicle,
                vehicleList: this.editAvailabilityConfig.vehicleList,
                cutoffTimeOverride: this.editAvailabilityConfig.cutoffTimeOverride,
                customDropoff1: this.editAvailabilityConfig.customDropoff1,
                customDropoff2: this.editAvailabilityConfig.customDropoff2,
                customDropoff3: this.editAvailabilityConfig.customDropoff3,
                customPickup1: this.editAvailabilityConfig.customPickup1,
                customPickup2: this.editAvailabilityConfig.customPickup2,
                customPickup3: this.editAvailabilityConfig.customPickup3,
                price1: this.editAvailabilityConfig.price1,
                price2: this.editAvailabilityConfig.price2,
                price3: this.editAvailabilityConfig.price3,
            });
            data = await this.rpcClient.poolAvailabilityConfig.getPoolAvailabilityConfigDetailsById(
                this.availabilityConfig.id,
            );
        } catch (error) {
            this.editError = error;
        }
        this.keepAvailabilityConfig = data;
        this.editLoading = false;
        this.editAvailabilityConfig = undefined;
    }

    @action
    public onCancel() {
        this.editAvailabilityConfig = undefined;
    }
}
