import { SimpleUser } from "@legacy/domain/SimpleUser";
import autobind from "autobind-decorator";
import { action, computed, observable, toJS } from "mobx";
import queryString from "query-string";

import { container, stores, RoutingStore } from "../../container";
import { getRpcClient, RpcClient } from "../../rpc-browser-sdk";

@autobind
export class DriverLayoutStore {
    public constructor() {
        this.rpcClient = getRpcClient();
    }

    public routerStore = container.get<RoutingStore>(stores.routing);

    public rpcClient: RpcClient;

    @observable
    public simpleUsers: Array<SimpleUser> = [];

    @observable
    public selectedDriverId: string = "";

    @observable
    public isDriversLoading: boolean = false;

    @computed
    public get userId(): string | undefined {
        let userId: string | undefined;

        if (this.routerStore.location.query.userId) {
            userId = this.routerStore.location.query.userId;
        } else if (this.routerStore.location.query.payouts_userId) {
            userId = this.routerStore.location.query.payouts_userId;
        }

        return userId;
    }

    @action
    public async fetchData(): Promise<void> {
        if (this.userId == undefined) {
            this.selectedDriverId = "";
            this.simpleUsers = [];
        } else {
            this.selectedDriverId = this.userId;
            this.simpleUsers = await this.rpcClient.user.retrieveSimpleUsers({ userIds: [this.userId] });
        }
    }

    @action
    public async driversFetch(): Promise<void> {
        this.isDriversLoading = true;
        this.simpleUsers = await this.rpcClient.user.retrieveSimpleUsers({ isDriver: true });
        this.isDriversLoading = false;
    }

    public isDataFetched(): this is DriverLayoutStore & DriverLayoutStoreDataFetched {
        return this.simpleUsers != undefined;
    }

    @action
    public selectDriver(driverId: string): void {
        this.selectedDriverId = driverId;
        const query = toJS(this.routerStore.location.query);
        query.userId = driverId;

        this.routerStore.push({
            pathname: this.routerStore.location.pathname,
            search: `?${queryString.stringify(query)}`,
        });
    }
}

interface DriverLayoutStoreDataFetched {
    simpleUsers: Array<SimpleUser>;
}
